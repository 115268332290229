import Carousel from 'components/login/Carousel';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import PasswordResetNewPasswordWindow from 'components/passwordReset/PasswordResetNewPasswordWindow';
import MobilePasswordResetNewPassword from 'components/passwordReset/MobilePasswordResetNewPassword';
import { useWindowSize } from 'utils/useWindowSize';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/browserUtils';

const PasswordResetNewPassword = () => {
  const windowSize = useWindowSize();

  return (
    <section className="passwordReset">
      <Helmet>
        <title>EDUBO – Obnova hesla</title>
        <link rel='canonical' href={`${websiteUrl}/new-password`}/>
      </Helmet>
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <PasswordResetNewPasswordWindow />
          </>
        }
        mobile={
          <MobilePasswordResetNewPassword />
        }
      />
    </section>
  );
}

export default PasswordResetNewPassword;