import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Eye from "images/eye.svg?react";
import EyeSlashed from "images/eye-slashed.svg?react";
import useLogin from "./useLogin";
import { FormErrorMessage } from "components/registration/MobileRegistration";
import AppBar from "components/mobile-utils/AppBar";
import MobileCarousel from "./MobileCarousel";

const MobileLogin = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, errors, onSubmit } = useLogin();
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div className="w-full h-mobile-full">
      <AppBar />
      <section className="login_window_mobile w-full px-10">
        <MobileCarousel />
        <form className="data w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full relative">
            <div>E-MAIL:</div>
            <input className="w-full" placeholder="Zadejte e-mail" maxLength={320} {...register("email")} />
            {errors.email && <FormErrorMessage message={errors.email.message} />}
          </div>
          <div className="w-full relative">
            <div className="flex flex-col relative">
              <div className='mt-4'>HESLO:</div>
              <input className="w-full" type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Zadejte heslo" {...register("password")} />
              {passwordShown ?
                <Eye className="eye shown" onClick={() => setPasswordShown(!passwordShown)} />
              : 
                <EyeSlashed className="eye" onClick={() => setPasswordShown(!passwordShown)} />
              }
            </div>
            {errors.password && <FormErrorMessage message={errors.password.message} />}
            {errors.apiError && <FormErrorMessage message={errors.apiError?.message} />}
          </div>
          <button type="submit" className="select-none mt-8">Přihlásit se</button>
          <button type="button" className="register mt-4" onClick={() => navigate("/registration")}>Registrovat se</button>
          <div className="flex items-center remember-me mt-12">
            <input id="remember" type="checkbox" className="square-checkbox" {...register("remember")} />
            <label htmlFor="remember" className="ml-2">ZAPAMATOVAT SI PŘIHLÁŠENÍ</label>
          </div>
          <div className='flex mt-2 items-center justify-center forgotten-password mb-1'>
            <Link to="/change-password">ZAPOMENUTÉ HESLO</Link>
          </div>
        </form>
      </section>
    </div>
  );
};

export default MobileLogin;
