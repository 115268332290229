import { useState, useEffect } from 'react';

const mobileQuery = '(max-width: 600px)';

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = (event: MediaQueryListEvent) => setMatches(event.matches);
    
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
};

export const useMobileQuery = (): boolean => {
  const [mobile, setMobile] = useState(window.matchMedia(mobileQuery).matches);

  useEffect(() => {
    const media = window.matchMedia(mobileQuery);
    const listener = (event: MediaQueryListEvent) => setMobile(event.matches);
    
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, []);

  return mobile;
};

export default useMediaQuery;
