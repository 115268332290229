type ButtonProps = {
  typeOfButton: "light",
  children?: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({typeOfButton, children, ...props}: ButtonProps) => {
  let className = "";

  if (typeOfButton === "light") className = "select-none text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 whitespace-nowrap disabled:hover:bg-white h-min justify-center text-center font-medium flex items-center rounded-lg text-sm p-2 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50";

  return (
    <button 
      type="button"
      className={className}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
