export const websiteUrl = import.meta.env.VITE_WEBSITE_URL;

export const isMacSafariFirefox = () => {
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const isMac = macosPlatforms.indexOf(window.navigator.platform) !== -1;
  const ua = window.navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(ua);
  const isFirefox = ua.indexOf('Firefox') > -1;

  return isMac && (isSafari || isFirefox);
};

export const optionsFrom: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' };
export const optionsTo: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };