import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUser } from 'redux/authSlice';
import axiosRequest from 'utils/axios';

type Form = {
  email: string;
  password: string;
  remember?: boolean;
  apiError?: string
};

type LoginResponse = {
  user: {
    _id: string;
    email: string;
    data_id: string;
    ts_created: string;
    role: "student" | "teacher" | "parent";
    class_id: string;
  };
};

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formSchema = yup.object().shape({
    email: yup.string()
      .required("Toto pole je povinné!")
      .email("E-mail není ve platném formátu!")
      .max(320, "E-mail není ve platném formátu!"),
    password: yup.string()
      .required("Toto pole je povinné!")
      .max(100, "Heslo nesmí být delší než 100 znaků!"),
    remember: yup.boolean()
  });

  const {setError, register, handleSubmit, formState: { errors }} = useForm<Form>({
    resolver: yupResolver(formSchema)
  });

  const onSubmit = async (data: Form) => {
    const response = await axiosRequest<LoginResponse>("POST", "/api/login", {
      email: data.email,
      password: data.password,
      remember: data.remember
    });
    if (!response.success) {
      if (response.errorMessage.includes("Incorrect")) {
        setError("password", {
          type: "server",
          message: "Nesprávný e-mail nebo heslo!"
        });
        return;
      }
      setError("apiError", {
        type: "server",
        message: "Někde nastala chyba zkuste to znovu!",
      });
      return;
    }
    dispatch(setUser({ userInfo: response.data.user, rememberMe: data.remember ?? false }));
    if (response.data.user.role === "student" || response.data.user.role === "parent") {
      navigate("/view-selection");
    } else {
      navigate("/lesson-menu");
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit
  };
}