import CloseIcon from "images/close.svg?react";

type ErrorSnackbarProps = {
  message: string;
  fade: string;
  closeSnackbar: () => void;
};

const ErrorSnackbar = ({ message, fade, closeSnackbar }: ErrorSnackbarProps) => (
  <div className={`fixed top-0 left-1/2 z-50 transform -translate-x-1/2 mt-8 max-w-lg ${fade}`} onClick={closeSnackbar}>
    <div className="bg-red-100 border border-red-700 text-red-700 rounded relative px-4 py-3 shadow-md break-all">
      <div className="flex items-center">
        <p className="mr-2">{message}</p>
        <div aria-label="close" className='h-3 w-3 min-w-3 cursor-pointer'>
          <CloseIcon />
        </div>
      </div>
    </div>
  </div>
);

export default ErrorSnackbar;
