import { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from 'redux/store';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import CalendarMenuWindow from 'components/student/calendar-menu/CalendarMenuWindow';
import MobileCalendarMenu from 'components/student/calendar-menu/MobileCalendarMenu';
import type { Calendar, CalendarData, DesktopCalendarLessonData, FullMaterial } from 'types/StudentData';
import { setMenu } from 'redux/editorSlice';
import { useMobileQuery } from 'utils/useMediaQuery';
import dayjs from 'dayjs';
import axiosRequest from 'utils/axios';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/browserUtils';

type GetRatingResponse = {
  rating: string;
};

type CalendarResponse = {
  calendar: Calendar;
};

const StudentCalendarMenu = () => {
  const dispatch = useDispatch();
  const mobileViewport = useMobileQuery();
  const { openErrorSnackbar } = useSnackbar();
  const classId = useSelector((state: RootState) => state.auth.userInfo.class_id);

  // Lesson variables
  const [lessonNames, setLessonNames] = useState<string[]>([]);
  const [selectionLessons, setSelectionLessons] = useState<string[]>([]);
  const [activeLesson, setActiveLesson] = useState<string>();
  const [activeLessonIndex, setActiveLessonIndex] = useState<number>(0);
  const [lessonRating, setLessonRating] = useState<string>();

  // Calendar variables
  const [calendarData, setCalendarData] = useState<Calendar>();
  const [calendarDayData, setCalendarDayData] = useState<CalendarData[]>();
  const [lessonProgress, setLessonProgress] = useState<string[]>()
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lastDate, setLastDate] = useState(new Date());

  // Other variables
  const [lessonData, setLessonData] = useState<DesktopCalendarLessonData>();
  const [savedMaterials, setSavedMaterials] = useState<FullMaterial[]>();

  // Obtain calendar data when the page loads and obtain lessons for current date
  useEffect(() => {
    obtainCalendarData();
    dispatch(setMenu("calendar"));
  }, []);

  useEffect(() => {
    obtainLessons();
  }, [calendarData]);

  useEffect(() => {
    setLastDate(selectedDate);
    if (selectedDate.getFullYear() === lastDate.getFullYear() && selectedDate.getMonth() === lastDate.getMonth()) obtainLessons();
    else obtainCalendarData();
  }, [selectedDate]);

  useLayoutEffect(() => {
    getLessonProgress();
  }, [calendarDayData]);

  useEffect(() => {
    // Skip for mobile
    if (mobileViewport || !activeLesson) return;
    getSetLessonMaterialsRating();
  }, [activeLesson]);

  // Change lesson selection when another month in the calendar is clicked
  const obtainCalendarData = async () => {
    const formattedDate = selectedDate.toLocaleDateString('cs-CZ', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/\s/g, '');
    const response = await axiosRequest<CalendarResponse>("GET", `/api/student/calendar/${classId}/${formattedDate}`);
    if (!response.success) {
      console.error(response.errorMessage);
      if (response.errorMessage.includes('Wrong date')) openErrorSnackbar('Bylo zvolené neplatné datum!');
      else if (response.errorMessage.includes('Class not found')) openErrorSnackbar('Třída nebyla nalezena!');
      else openErrorSnackbar('Nepodařilo se načíst kalendář!');
      return;
    }
    setCalendarData(response.data.calendar);
    setLessonData(undefined);
    setLessonNames([]);
    setSavedMaterials([]);
    setActiveLesson(undefined);
  };

  // Sets lesson names and lessons list based on day
  const obtainLessons = () => {
    if (!calendarData) return;
    const date: number = dayjs(Object.keys(calendarData)[0], 'D.M.YYYY', true).month();
    if (selectedDate.getMonth() !== date) obtainCalendarData();
    const calendarCopy: Calendar = structuredClone(calendarData);
    const newLessons: [{ key: string; value: string }] = [{ key: '', value: '' }];
    const formattedDate = selectedDate.toLocaleDateString('cs-CZ', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/\s/g, '');
    for (const date in calendarCopy) {
      if (date === formattedDate) {
        const orderedLessons = [...calendarCopy[date]].sort((a, b) => {
          const aMinTsFrom = Math.min(...a.days_of_usage.map(day => new Date(day.ts_from).getTime()));
          const bMinTsFrom = Math.min(...b.days_of_usage.map(day => new Date(day.ts_from).getTime()));
          return aMinTsFrom - bMinTsFrom;
        });

        const lessonsNames: string[] = [];
        for (const lesson of orderedLessons) {
          const originalName = lesson.name;
          let newName = lesson.name;
          let suffix = 2;

          // eslint-disable-next-line
          while (true) {
            if (!lessonsNames.includes(newName)) {
              lessonsNames.push(newName);
              lesson.name = newName;
              break;
            }
            newName = `${originalName} (${suffix})`;
            suffix++;
          }
        }

        setCalendarDayData(orderedLessons);
        for (const lesson of orderedLessons) {
          if (newLessons[0].key === "") newLessons[0] = { key: lesson._id, value: lesson.name };
          else newLessons.push({ key: lesson._id, value: lesson.name });
        }
        const valuesLessons = newLessons.map(object => object.value);
        if (valuesLessons[0] !== "") {
          setLessonNames(valuesLessons);
          setActiveLesson(valuesLessons[0]);
        } else {
          setLessonNames([]);
          setActiveLesson(undefined);
        }
        break;
      }
    }
  };

  const getLessonProgress = () => {
    if (!calendarDayData) return;
    const currentDay = new Date();
    const newProgress: string[] = [];
    for (let i = 0; i < calendarDayData.length; i++) {
      const lesson = calendarDayData[i];
      for (let j = 0; j < lesson.days_of_usage.length; j++) {
        const time = lesson.days_of_usage[j];
        const dateFrom = new Date(time.ts_from);
        const dateTo = new Date(time.ts_to);
        if (dateFrom.getDate() !== selectedDate.getDate()) {
          if (dateFrom.getDate() > selectedDate.getDate()) {
            dateFrom.setDate(dateFrom.getDate() - 1);
            dateFrom.setHours(23, 59, 59, 0);
          } else continue;

        }
        if (dateFrom > currentDay) {
          newProgress.push("pending");
          continue;
        }
        if (dateFrom < currentDay && dateTo > currentDay) {
          newProgress.push("ongoing");
          continue;
        }
        if (dateTo < currentDay) {
          newProgress.push("done");
        }
      }
    }
    setLessonProgress(newProgress);
  };

  const rateLessonMaterials = async (rating: string) => {
    if (!calendarDayData) return; 
    const lesson = calendarDayData[activeLessonIndex];
    const lessonId = lesson?._id;

    const response = await axiosRequest("POST", `/api/lesson/${lessonId}/rate`, {
      rating: rating
    });
    if (!response.success) {
      console.error(response.errorMessage);
      if (response.errorMessage.includes('Invalid rating')) openErrorSnackbar('Neplatné hodnocení!');
      else if (response.errorMessage.includes('Lesson not found')) openErrorSnackbar('Hodina nebyla nalezena!');
      else if (response.errorMessage.includes('You are not in this class!')) openErrorSnackbar('Nejste studentem této třídy!');
      else openErrorSnackbar('Nepodařilo se uložit hodnocení');
      return;
    }
    setLessonRating(rating);
  };

  const getSetLessonMaterialsRating = async () => {
    if (!calendarDayData) return;
    const lesson = calendarDayData[activeLessonIndex];
    if (!lesson) return;
    const lessonId = lesson._id;
    const response = await axiosRequest<GetRatingResponse>("GET", `/api/student/lesson/${lessonId}/rating`);
    if (!response.success) {
      console.error(response.errorMessage);
      setLessonRating("");
      if (response.errorMessage.includes('Lesson not found')) openErrorSnackbar('Hodina nebyla nalezena!');
      else openErrorSnackbar('Nepodařilo se načíst hodnocení');
      return;
    }
    setLessonRating(response.data.rating);
  };

  return (
    <section className="student-calendar-lesson-menu">
      <Helmet>
        <title>EDUBO – Zobrazení kalendáře</title>
        <link rel='canonical' href={`${websiteUrl}/calendar`}/>
      </Helmet>
      <ResponsiveComponent
        desktop={
          <CalendarMenuWindow
            lessonNames={lessonNames}
            selectionLessons={selectionLessons}
            setSelectionLessons={setSelectionLessons}
            activeLesson={activeLesson}
            setActiveLesson={setActiveLesson}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            savedMaterials={savedMaterials}
            lessonData={lessonData}
            lessonProgress={lessonProgress}
            calendarDayData={calendarDayData}
            setSavedMaterials={setSavedMaterials}
            setLessonData={setLessonData}
            setActiveLessonIndex={setActiveLessonIndex}
            rateLessonMaterials={rateLessonMaterials}
            lessonRating={lessonRating}
          />
        }
        mobile={
          <MobileCalendarMenu
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            lessonProgress={lessonProgress}
            calendarDayData={calendarDayData}
            activeLesson={activeLesson}
            obtainCalendarData={obtainCalendarData}
          />
        }
      />
    </section>
  );
};

export default StudentCalendarMenu;
