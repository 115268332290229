import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import axiosRequest from 'utils/axios';

type RegisterResponse = {
  message: string;
};

type Form = {
  email: string;
  password: string;
  confirmPwd: string;
  key: string;
  gdpr: true;
  apiError?: string;
};

export default () => {
  const navigate = useNavigate();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  
  const formSchema = yup.object().shape({
    email: yup.string()
      .required("Toto pole je povinné!")
      .email("E-mail není ve platném formátu!")
      .max(320, "E-mail není ve platném formátu!"),
    password: yup.string()
      .required("Toto pole je povinné!")
      .min(6, "Heslo musí být minimálně 6 znaků dlouhé!")
      .max(100, "Heslo nesmí být delší než 100 znaků!"),
    confirmPwd: yup.string()
      .required("Toto pole je povinné!")
      .oneOf([yup.ref("password")], "Hesla se neshodují!"),
    key: yup.string()
      .required("Toto pole je povinné!"),
    gdpr: yup.boolean()
      .required("Toto pole je povinné!")
      .isTrue("Toto pole je povinné!")
  })

  const {setError, register, handleSubmit, formState: { errors }} = useForm<Form>({ 
    resolver: yupResolver(formSchema)
  });

  const onSubmit = async (data: Form) => {
    const response = await axiosRequest<RegisterResponse>("POST", "/api/registration", {
      email: data.email,
      password: data.password,
      key: data.key,
      gdpr: data.gdpr
    });
    if (!response.success) {
      if (response.errorMessage.includes("already exists")) {
        setError("apiError", {
          type: "server",
          message: 'Tento e-mail je již používán!',
        });
      } else if (response.errorMessage.includes("Key is expired!")) {
        setError("apiError", {
          type: "server",
          message: 'Platnost klíče vypršela!',
        });
      } else if (response.errorMessage.includes("Key is already used!")) {
        setError("apiError", {
          type: "server",
          message: 'Klíč byl již použit!',
        });
      } else if(response.errorMessage.includes("Incorrect key!")) {
        setError("apiError", {
          type: "server",
          message: 'Nesprávný klíč!',
        });
      } else {
        setError('apiError', {
          type: "server",
          message: 'Někde nastala chyba zkuste to znovu!',
        });
      }
      return;
    }
    setIsSuccessfullySubmitted(true);
    setTimeout(() => {
      navigate("/");
    }, 750);
  };
  
  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    isSuccessfullySubmitted
  };
};