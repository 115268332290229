import type { CSSProperties } from "react";
import { Tooltip } from 'flowbite-react';
import Pending from "images/student-lesson-menu-icons/pending.svg?react";
import Ongoing from "images/student-lesson-menu-icons/ongoing.svg?react";
import Done from "images/student-lesson-menu-icons/done.svg?react";
import SmileFace from "images/student-lesson-menu-icons/smile-face.svg?react";

type StudentSelectionMenuProps = {
  list: string[];
  setActiveList: (value: string) => void;
  setActiveLessonIndex: (value: number) => void;
  selectionList: string[];
  setSelectionList: (value: string[]) => void;
  lessonProgress: string[] | undefined;
};

const StudentSelectionMenu = ({list, setActiveList, setActiveLessonIndex, selectionList, setSelectionList, lessonProgress}: StudentSelectionMenuProps) => {

  // Handles the click event for a given value
  const handleClick = (value: string) => {
    setActiveList(value);
    setActiveLessonIndex(list.indexOf(value))
    select(list.indexOf(value));
  };

  // Returns the CSS properties for styling an item based on its index
  const getItemStyle = (index: number): CSSProperties => {
    const selectedItem = selectionList[index] === "selected" ? "rgba(210, 174, 223, 1)" : "rgba(128, 7, 170, 0.1)";
    const borderColor =
      selectionList[index] === "selected"
        ? "rgb(185, 124, 208)"
        : "rgba(210, 174, 223, 1)";

    return {
      userSelect: "none",
      width: "100%",
      padding: 4,
      margin: "0 0 4px 0",
      border: `solid 1px ${borderColor}`,
      borderRadius: "4px",
      background: selectedItem,
      position: "relative",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer"
    };
  };

  // Updates the selection state based on the index
  const select = (index: number) => {
    const newSelection = new Array(list.length);
    newSelection.fill("unselected");
    newSelection[index] = "selected";
    setSelectionList(newSelection);
  };

  return (
    <div className={`student-selection-menu ${list.length > 0 ? "" : "items-center flex justify-center h-full"}`}>
      {list.length > 0 ? list.map((value, index) => (
        <div className="student-lesson" key={index}>
          <div
            onClick={() => handleClick(value)}
            style={getItemStyle(index)}
          >
            {value}
          </div>
          {lessonProgress && lessonProgress.length === list.length ?
            <Tooltip
              className="status-tooltip"
              content={lessonProgress[index] === "pending"
                ? "Neproběhlá hodina"
                : lessonProgress[index] === "ongoing"
                  ? "Aktivní hodina"
                  : lessonProgress[index] === "done"
                    ? "Dokončená hodina"
                    : null}
              placement='right'
              style='dark'
              trigger='hover'
            >
              {lessonProgress[index] === "pending"
                ? <Pending className="student-status-icon pending" />
                : lessonProgress[index] === "ongoing"
                  ? <Ongoing className="student-status-icon ongoing" />
                  : lessonProgress[index] === "done"
                    ? <Done className="student-status-icon done" />
                    : null}
            </Tooltip>
          : null}
        </div>
        ))
      : 
        <div className='no-lessons'>
          <SmileFace className='no-lessons-smile-face smile-face-desktop'/>
          <span className='no-lessons-text'>Nic zde není</span>
        </div>
      }
    </div>
  );
};

export default StudentSelectionMenu;
