import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import editorReducer from './editorSlice';
import errorReducer, { errorHandlingMiddleware } from './errorSlice';

const lessonPersistConfig = {
  key: 'lesson',
  storage
};

const rootReducers = combineReducers({ 
  auth: authReducer,
  editor: persistReducer(lessonPersistConfig, editorReducer),
  error: errorReducer
});

export const store = configureStore({
  reducer: rootReducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(errorHandlingMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistedStore = persistStore(store);
