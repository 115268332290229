import { Calendar } from 'react-calendar';
import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';
import { useModal } from "contexts/ModalProvider";
import Next from "images/student-lesson-menu-icons/next.svg?react";
import Previous from "images/student-lesson-menu-icons/previous.svg?react";
import type { Value } from 'react-calendar/dist/cjs/shared/types';

type CalendarModalProps = {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
};

const CalendarModal = ({selectedDate, setSelectedDate}: CalendarModalProps) => {
  const { closeModal } = useModal();

  return (
    <div className='mobile-calendar-modal'>
      <KeyboardShortcuts context="mobileCalendarModal" />
      <h1 className='calendar-modal-heading'>Kalendář</h1>
      <Calendar
        className="student-calendar-content"
        value={selectedDate}
        onChange={(newDate: Value) => setSelectedDate((newDate instanceof Date) ? new Date(newDate) : new Date())}
        locale='cs-CS'
        maxDate={new Date("2050-12-31")}
        minDate={new Date("2023-06-01")}
        tileClassName={'calendar-tile'}
        nextLabel={<Next />}
        prevLabel={<Previous />}
        next2Label={null}
        prev2Label={null}
        minDetail={'year'}
        showFixedNumberOfWeeks={true}
        onClickDay={closeModal}
      />
      <button type='button' id="closeButtonMobileCalendarModal" className="close-button" onClick={closeModal}>Zavřít</button>
    </div>
  );
};

export default CalendarModal;
