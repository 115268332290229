export type iUserData = {
	_id: string;
	ts_created: string;
	ts_updated: string;
	id_of_user: string;
	author: string;
	classes: iClass[];
};

export type iClass = {
	_id: string;
	name: string;
	subjects: iSubject[];
};

export type iDaysOfUsage = {
	ts_from: string;
	ts_to: string;
};

export type iSubject = {
	_id: string;
	name: string;
	teaching_units: iTeachingUnit[];
};

export type iTeachingUnit = {
	_id: string;
	name: string;
	notes: string;
	lessons: iMenuLesson[]
};

export type iMenuLesson = {
	_id: string;
	name: string;
	days_of_usage: iDaysOfUsage[];
	published: boolean;
};

export type curriculumArray = {
	0: boolean
	1: string | undefined
};

export type Curriculum = {
	subject: string,
	curriculums: string[]
};

export type AssignedCurriculum = {
	id_of_subject: string
	curriculums: string[]
};

export type CombinedCurriculum = {
  _id: string;
  id_of_user: string;
  curriculums_list: Curriculum[];
  assigned_curriculums: AssignedCurriculum[];
};

export type iLessonMenuIndexes = {
	activeClassIndex: number,
	activeSubjectIndex: number,
	activeTeachingUnitIndex: number,
	activeLessonIndex: number
};

export type UserInfo = {
	_id?: string;
	data_id?: string;
	email?: string;
	ts_created?: string;
	role?: string;
	class_id?: string;
};

export const blankUserInfo: UserInfo = {
	_id: undefined,
	data_id: undefined,
	email: undefined,
	class_id: undefined
};