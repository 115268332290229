import "main.css";
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/Login';
import Registration from "./pages/Registration";
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import SnackbarProvider from "./contexts/SnackbarProvider";
import { ModalProvider } from "./contexts/ModalProvider";
import { setError } from './redux/errorSlice';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from './utils/ErrorHandling';
import ViewSelection from "./pages/ViewSelection";
import PasswordResetEmail from './pages/PasswordResetEmail';
import PasswordResetNewPassword from 'pages/PasswordResetNewPassword';
import InfoPage from 'pages/InfoPage';
import { HelmetProvider } from 'react-helmet-async';
import LoadingPage from 'pages/LoadingPage';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import StudentLessonMenu from "pages/StudentLessonMenu";
import StudentCalendarMenu from "pages/StudentCalendarMenu";

const Editor = lazy(() => import('./pages/Editor'));
const LessonMenu = lazy(() => import('./pages/LessonMenu'));
const LessonView = lazy(() => import('./pages/LessonView'));

// TODO: v1.2
// Student Menus layout
// Classes view of students in LessonMenu
// Reload student data after changing class

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Global error handler for uncaught exceptions
    window.onerror = (message, source, lineno, colno, error) => {
      console.error('App handler: Uncaught Error:', { message, source, lineno, colno, error });
      dispatch(setError(JSON.stringify({ message, source, lineno, colno, error })));
    }

    // Global handler for unhandled promise rejections
    window.onunhandledrejection = (event) => {
      console.error('App handler: Unhandled Promise Rejection:', event.promise, 'Reason:', event.reason);
      dispatch(setError(JSON.stringify({ event })));
    }
  }, []);

  return (
    <ErrorBoundary>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <SnackbarProvider>
              <ModalProvider>
                <Routes>
                  <Route path="/" element={<ProtectedRoute redirectLoggedUser><Login /></ProtectedRoute>} />
                  <Route path="/editor" element={<ProtectedRoute userIsNeeded allowedRoles={['teacher']}><Suspense fallback={<LoadingPage/>}><Editor /></Suspense></ProtectedRoute>} />
                  <Route path="/registration" element={<ProtectedRoute redirectLoggedUser><Registration /></ProtectedRoute>} />
                  <Route path="/lesson-menu" element={<ProtectedRoute userIsNeeded allowedRoles={['teacher']}><Suspense fallback={<LoadingPage/>}><LessonMenu /></Suspense></ProtectedRoute>} />
                  <Route path="/view-selection" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><ViewSelection /></ProtectedRoute>} />
                  <Route path="/subjects/*" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><StudentLessonMenu/></ProtectedRoute>}/>
                  <Route path="/lesson-view" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><Suspense fallback={<LoadingPage/>}><LessonView/></Suspense></ProtectedRoute>} />
                  <Route path="/calendar" element={<ProtectedRoute userIsNeeded allowedRoles={['student', 'parent']}><StudentCalendarMenu/></ProtectedRoute>} />
                  <Route path="/change-password" element={<ProtectedRoute redirectLoggedUser><PasswordResetEmail/></ProtectedRoute>} />
                  <Route path="/new-password" element={<ProtectedRoute redirectLoggedUser><PasswordResetNewPassword/></ProtectedRoute>} />
                  <Route path="/about" element={<ProtectedRoute><InfoPage page={"about"}/></ProtectedRoute>} />
                  <Route path="/contacts" element={<ProtectedRoute><InfoPage page={"contacts"}/></ProtectedRoute>} />
                  <Route path="/gdpr" element={<ProtectedRoute><InfoPage page={"gdpr"}/></ProtectedRoute>} />
                  <Route path="/notes" element={<ProtectedRoute><InfoPage page={"notes"}/></ProtectedRoute>} />
                </Routes>
              </ModalProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </HelmetProvider>
    </ErrorBoundary >
  );
};

export default App;
