import StudentLessonMenuWindow from "components/student/lesson-menu/StudentLessonMenu";
import MobileLessonMenu from "components/student/lesson-menu/MobileLessonMenu";
import ResponsiveComponent from "utils/ResponsiveComponent";
import { Helmet } from "react-helmet-async";
import { websiteUrl } from "utils/browserUtils";

const StudentLessonMenu = () => {

  return (
    <>
      <Helmet>
        <title>EDUBO – Zobrazení předmětů</title>
        <link rel='canonical' href={`${websiteUrl}/subjects`}/>
      </Helmet>
      <ResponsiveComponent 
        desktop={
          <StudentLessonMenuWindow />
        }
        mobile={
          <MobileLessonMenu />
        }
      />
    </>
  );
};

export default StudentLessonMenu;
