import SmileFace from "images/student-lesson-menu-icons/smile-face.svg?react";
import Pending from "images/student-lesson-menu-icons/pending.svg?react";
import Ongoing from "images/student-lesson-menu-icons/ongoing.svg?react";
import Done from "images/student-lesson-menu-icons/done.svg?react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLessonId, setTeachersId } from 'redux/editorSlice';
import type { LessonData } from "types/StudentData";

type MobileCalendarLessonsProps = {
  lessonDatas: LessonData[];
  lessonProgress: string[] | undefined;
};

const MobileCalendarLessons = ({lessonDatas, lessonProgress}: MobileCalendarLessonsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenButton = (index: number) => {
    if (!lessonDatas) return;
    dispatch(setLessonId(lessonDatas[index]._id));
    dispatch(setTeachersId(lessonDatas[index].teachers_data_id));
    navigate('/lesson-view');
  };

  return (
    <div className={`mobile-calendar-lesson-menu-lessons ${lessonDatas && lessonDatas.length > 0 ? "" : 'justify-center'}`}>
      {lessonDatas?.length > 0 ? lessonDatas?.map((lesson, index) => {
        return (
          <div key={index} onClick={() => handleOpenButton(index)} className="mobile-calendar-lesson-menu-lesson">
            <div className='flex-row flex'>
              <span className="subject">{lesson.subject}</span>
              {lessonProgress && (
                lessonProgress[index] === "pending"
                  ? <Pending className="student-status-icon-mobile" />
                : lessonProgress[index] === "ongoing"
                  ? <Ongoing className="student-status-icon-mobile" />
                : lessonProgress[index] === "done"
                  ? <Done className="student-status-icon-mobile" />
                : null)
              }
            </div>
            <span className="time-and-teacher">
              {lesson.time}, {lesson.teacher}
            </span>
            <span className="teaching-unit">{lesson.teachingUnit}</span>
            <span className="lesson-name">{lesson.name}</span>
          </div>
        )})
      : 
        <div className='no-lessons'>
          <SmileFace className='no-lessons-smile-face'/>
          <span className='no-lessons-text'>Nic zde není</span>
        </div>
      }
    </div>
  );
};

export default MobileCalendarLessons;
