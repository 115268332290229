import SmileIcon from 'images/smile.svg?react';
import ArrowIcon from 'images/arrow.svg?react';
import { Link, type To, useNavigate } from 'react-router-dom';
import { type ReactNode, useEffect, useState } from 'react';
import Logout from 'components/nav/logout/Logout';
import { useSelector } from 'react-redux';
import type { RootState } from 'redux/store';
import { isMacSafariFirefox } from 'utils/browserUtils';
import type { parentKey } from 'types/StudentData';
import axiosRequest from 'utils/axios';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { useModal } from 'contexts/ModalProvider';
import ChangeClass from 'components/student/nav/change-class/ChangeClass';

type ParentKeysResponse = {
  keys: parentKey[];
};

type DynamicSideBarProps = {
  currentPath: string;
  onClose: () => void;
  open: boolean;
};

const DynamicSideBar = ({currentPath, onClose, open}: DynamicSideBarProps) => {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const menu = useSelector((state: RootState) => state.editor.menu);
  const teachersId= useSelector((state: RootState) => state.editor.teachersId);
  const lessonId = useSelector((state: RootState) => state.editor.lessonId);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const studentsClass = useSelector((state: RootState) => state.editor.class);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const viewOption = currentPath === "/calendar" ? "předměty" : "kalendář";
  const username = userInfo.email ?? "";
  const isUserStudent = userInfo.role === 'student';

  const navigateTo = (path: To) => {
    navigate(path);
    onClose();
  };

  const openPDFExport = () => {
    const url = `/pdf/student/${teachersId}/${lessonId}`;
  
    if (isMacSafariFirefox()) {
      setTimeout(() => {
        const newTab = window.open("", "_blank");
        if (newTab) { 
          newTab.location.href = url;
        } else {
          console.error("Failed to open new tab");
        }
      });
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  };

  const handleChangeClass = () => {
    showModal(<ChangeClass/>);
    onClose();
  };

  switch(true) {
    case /^\/calendar/.test(currentPath):
    case /^\/subjects/.test(currentPath):
      return (
        <SidebarContent onClose={onClose} open={open}>
          <UserDisplay username={username} studentsClass={studentsClass} role={userInfo.role}/>
          <div className="view-container">
            <button type='button' className="view-button" onClick={() => navigateTo(currentPath === "/calendar" ? "/subjects" : "/calendar")}>
              <ArrowIcon className='goto-arrow'/>
              Přepnout pohled ({viewOption})
            </button>
            {isUserStudent && 
              <>
                <ParentKeys />
                <button type='button' className="view-button" onClick={handleChangeClass}>
                  <ArrowIcon className='goto-arrow'/>
                  Změnit třídu
                </button>
              </>
            }
          </div>
          <Refs/>
        </SidebarContent>
      );

    case /^\/view-selection/.test(currentPath):
      return (
        <SidebarContent onClose={onClose} open={open}>
          <UserDisplay username={username} studentsClass={studentsClass} role={userInfo.role}/>
          {isUserStudent && (
            <div className="view-container">
              <ParentKeys />
              <button type='button' className="view-button" onClick={handleChangeClass}>
                <ArrowIcon className='goto-arrow'/>
                Změnit třídu
              </button>
            </div>
          )}
          <Refs />
        </SidebarContent>
      );

    case /^\/lesson-view/.test(currentPath):
      return (
        <SidebarContent onClose={onClose} open={open}>
          <UserDisplay username={username} studentsClass={studentsClass} role={userInfo.role}/>
          <div className="view-container">
            <button type='button' className="view-button" onClick={() => setDropdownOpen(!dropdownOpen)}>
              <ArrowIcon className={`goto-arrow ${dropdownOpen ? 'open' : ''}`}/>
              Soubor
            </button>
            {dropdownOpen && (
              <div className={`dropdown ${dropdownOpen ? 'visible' : 'hidden'}`}>
                <div className="view-option">
                  <p onClick={openPDFExport}>Exportovat do PDF</p>
                </div>
                <div className="view-option">
                  <p onClick={() => navigateTo(menu === 'calendar' ? '/calendar' : '/subjects')}>Zpět do menu</p>
                </div>
              </div>
            )}
            {isUserStudent && 
              <ParentKeys />
            }
          </div>
          <Refs/>
        </SidebarContent>
      );

    case /^\/about/.test(currentPath):
    case /^\/contacts/.test(currentPath):
    case /^\/gdpr/.test(currentPath):
    case /^\/notes/.test(currentPath):
      return (
        <SidebarContent onClose={onClose} open={open}>
          {userInfo._id ?
            <>
              <UserDisplay username={username} studentsClass={studentsClass} role={userInfo.role}/>
              <div className="view-container">
                <button type='button' className="view-button" onClick={() => navigateTo("/view-selection")}>
                  <ArrowIcon className='goto-arrow'/>
                  Výběr pohledů
                </button>
                {isUserStudent &&
                  <ParentKeys />
                }
              </div>
              <DynamicRefs currentPath={currentPath}/>
            </>
          :
            <DynamicRefs currentPath={currentPath}/>
          }
        </SidebarContent>
      );

    case /^\//.test(currentPath):
    case /^\/registration/.test(currentPath):
    case /^\/change-password/.test(currentPath):
    case /^\/new-password/.test(currentPath):
      return (
        <SidebarContent onClose={onClose} open={open}>
          <DynamicRefs currentPath={currentPath}/>
        </SidebarContent>
      );

    default:
      return null;
  }
};

type SideBarContentProps = {
  onClose: () => void;
  children: ReactNode;
  open: boolean;
};

const SidebarContent = ({onClose, children, open}: SideBarContentProps) => (
  <div className="sidebar-content">
    <SideNav onClose={onClose} open={open}/>
    {children}
  </div>
);

const ParentKeys = () => {
  const { openErrorSnackbar } = useSnackbar();
  const [isDropdownParentsOpen, setDropdownParentsOpen] = useState(false);
  const [parentKeys, setParentKeys] = useState<parentKey[]>([]);

  useEffect(() => {
    if (!isDropdownParentsOpen || parentKeys.length !== 0) return;
    getParentKeys();
  }, [isDropdownParentsOpen]);

  const getParentKeys = async () => {
    const response = await axiosRequest<ParentKeysResponse>("GET", 'api/current-student/parent-keys');
    if (!response.success) {
      console.error(response.errorMessage);
      openErrorSnackbar("Nepodařilo se získat rodičovské klíče!");
      return;
    }
    if (!response.data.keys || response.data.keys.length < 1) {
      openErrorSnackbar("Nemáte žádné rodičovské klíče");
      return;
    }
    setParentKeys(response.data.keys);
  };

  return (
    <div className='parental-access-dropdown'>
      <button type='button' className="view-button" onClick={() => setDropdownParentsOpen(!isDropdownParentsOpen)}>
        <ArrowIcon className={`goto-arrow ${isDropdownParentsOpen ? 'open' : ''}`}/>
        Rodičovský přístup
      </button>
      {isDropdownParentsOpen && (
        <div className="dropdown-content">
          {parentKeys.map((key, index) => (
            <div className='key' key={index}>
              <p>{key.key}</p>
              {key.used && <span className="used">(použitý)</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const UserDisplay = ({username, studentsClass, role}: {username: string, studentsClass: string, role: string | undefined}) => (
  <>
    <div className="icon-container">
      <SmileIcon className="smile-icon" />
      <div className="username-class-container">
        {role === "parent" ? 
          <p>{username} - rodič</p>
        :
          <p>{username}</p>
        }
        <p>{studentsClass}</p>
      </div>
    </div>
    <div className="logout-container">
      <Logout />
    </div>
  </>
);

const SideNav = ({onClose, open}: {onClose: () => void, open: boolean}) => (
  <button type='button' className={`flex items-center close-sidebar ${open ? "open" : ""}`} onClick={onClose} aria-label='Zavřít menu'>
    <ArrowIcon />
  </button>
);

const DynamicRefs = ({currentPath}: {currentPath: string}) => (
  <div className="refs">
    {!/^\/about/.test(currentPath) && <Link to={"/about"}>O nás</Link>}
    {!/^\/notes/.test(currentPath) && <Link to={"/notes"}>Archiv aktualit</Link>}
    {!/^\/contacts/.test(currentPath) && <Link to={"/contacts"}>Kontakty</Link>}
    {!/^\/gdpr/.test(currentPath) && <Link to={"/gdpr"}>GDPR</Link>}
  </div>
);

const Refs = () => (
  <div className="refs">
    <Link to={"/about"}>O nás</Link>
    <Link to={"/notes"}>Archiv aktualit</Link>
    <Link to={"/contacts"}>Kontakty</Link>
    <Link to={"/gdpr"}>GDPR</Link>
  </div>
)

type SideBarProps = {
  currentPath: string;
  open: boolean;
  onClose: () => void;
};

const SideBar = ({ currentPath, open, onClose }: SideBarProps) => (
  <div className={`sidebar ${!open ? "sidebar-closed" : ""}`}>
    <DynamicSideBar currentPath={currentPath} onClose={onClose} open={open}/>
  </div>
);

export default SideBar;
