import useLessonMenuLogic from "./hooks/useLessonMenuLogic";
import AppBar from "components/mobile-utils/AppBar";
import StudentSelectionMenu from "./StudentSelectionMenu";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import FaChevron from "images/fa-chevron-up.svg?react";
import { useEffect } from "react";
import { MenuTypes } from "./StudentLessonMenu";

const MobileLessonMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    subjectNames,
    activeSubject,
    teachingUnitsNames,
    activeTeachingUnit,
    lessonNames,
    lessonProgress,
    setActiveSubject,
    setActiveTeachingUnit,
    setActiveLesson,
    setSelectionSubjectNames,
    setSelectionTeachingUnitsNames,
    setSelectionLessons,
    setSubjectsInUserData,
    setTeachingUnitsInUserData,
    setLessonsInUserData,
    obtainLessons,
    handleOpenButton
  } = useLessonMenuLogic();

  useEffect(() => {
    if (location.pathname !== "/subjects") navigate("/subjects");
  }, []);

  // a function for navigating to the next level of menu
  const nextMenu = () => {
    const currentPath = location.pathname;

    if (currentPath === '/subjects') {
      navigate("/subjects/teaching-units");
    } else if (currentPath === '/subjects/teaching-units') {
      navigate("/subjects/lessons");
    }
  };

  // a function for navigating to the previous level of menu
  const previousMenu = () => {
    if (location.pathname === "/subjects") navigate("/view-selection");
    else navigate(-1);
  };

  // a function that wraps the setSelectionList function and navigates to the next level of menu
  const nextMenuWrapper = (setSelectionList: (list: string[]) => void) => {

    return (list: string[]) => {
      setSelectionList(list);
      nextMenu();
    }
  };

  let title = '';
  if (location.pathname === '/subjects/teaching-units') {
    title = activeSubject;
  } else if (location.pathname === '/subjects/lessons') {
    title = activeTeachingUnit;
  } else {
    title = 'Předměty';
  }

  return (
    <>
      <AppBar />
      <div className="flex flex-col flex-grow">
        <div className="p-3 border-b border-black border-dashed flex items-center">
          <button
            type='button'
            onClick={previousMenu}
            className="flex items-center justify-center h-7 w-7 border border-black p-1 mr-2 rounded focus:outline-none"
          >
            <FaChevron className="-rotate-90 w-[18px]" />
          </button>
          {title}
        </div>
        <div className="p-3 flex flex-grow flex-col overflow-auto">
          <div className="p-1 border border-black rounded flex-grow">
            <Routes>
              <Route path="/" element={
                <StudentSelectionMenu
                  list={subjectNames}
                  setList={setSubjectsInUserData}
                  selectionList={[]}
                  setSelectionList={nextMenuWrapper(setSelectionSubjectNames)}
                  setActiveList={setActiveSubject}
                  subjectSelect={false}
                  type={MenuTypes.SUBJECT}
                  activeSubject={activeSubject}
                  obtainLessons={obtainLessons}
                  lessonProgress={lessonProgress}
                  mobile={true}
                  handleOpenButton={handleOpenButton}
                />
              } />
              <Route path="/teaching-units" element={
                <StudentSelectionMenu
                  list={teachingUnitsNames}
                  setList={setTeachingUnitsInUserData}
                  selectionList={[]}
                  setSelectionList={nextMenuWrapper(setSelectionTeachingUnitsNames)}
                  setActiveList={setActiveTeachingUnit}
                  subjectSelect={true}
                  obtainLessons={obtainLessons}
                  activeSubject={activeSubject}
                  type={MenuTypes.TEACHING_UNIT}
                  lessonProgress={lessonProgress}
                  mobile={true}
                  handleOpenButton={handleOpenButton}
                />
              } />
              <Route path="/lessons" element={
                <StudentSelectionMenu
                  list={lessonNames}
                  setList={setLessonsInUserData}
                  selectionList={[]}
                  setSelectionList={nextMenuWrapper(setSelectionLessons)}
                  setActiveList={setActiveLesson}
                  subjectSelect={false}
                  lessonProgress={lessonProgress}
                  type={MenuTypes.LESSON}
                  handleOpenButton={handleOpenButton}
                  mobile={true}
                  obtainLessons={obtainLessons}
                  activeSubject={activeSubject}
                />
              } />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileLessonMenu;
