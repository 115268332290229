import ViewSelectionWindow from 'components/view-selection/ViewSelectionWindow';
import Carousel from 'components/login/Carousel';
import { useEffect } from 'react';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobileViewSelection from 'components/view-selection/MobileViewSelection';
import { useDispatch } from 'react-redux';
import { setClass } from 'redux/editorSlice';
import { useWindowSize } from 'utils/useWindowSize';
import axiosRequest from 'utils/axios';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/browserUtils';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { useModal } from 'contexts/ModalProvider';
import ChangeClass from 'components/student/nav/change-class/ChangeClass';

type CurrentStudentResponse = {
  email: string;
  class: string;
};

const ViewSelection = () => {
  const { openErrorSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    loadStudentsInfo();
  }, []);

  const loadStudentsInfo = async () => {
    const response = await axiosRequest<CurrentStudentResponse>("GET", 'api/current-student');
    if (!response.success) {
      if (response.errorMessage.includes("User is not in any class")) {
        openErrorSnackbar("Nejste v žádné třídě! Zadejte nový kód třídy.");
        showModal(<ChangeClass />);
      }
      console.error(response.errorMessage);
      return;
    }
    dispatch(setClass(response.data.class));
  };

  return (
    <>
      <Helmet>
        <title>EDUBO – Výběr zobrazení</title>
        <link rel='canonical' href={`${websiteUrl}/view-selection`}/>
      </Helmet>
      <ResponsiveComponent
        desktop={
          <section className="view-selection">
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <ViewSelectionWindow />
          </section>
        }
        mobile={
          <MobileViewSelection />
        }
      />
    </>
  );
};

export default ViewSelection;
