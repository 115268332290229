import { useState, createContext, useContext, type ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'redux/store';
import UniversalModal from 'components/universal-modal/UniversalModal';

type ModalContextType = {
  showModal: (content: ReactNode) => void;
  closeModal: () => void;
  modalOpen: boolean;
};

export type ModalProps = {
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const modalActivity = useSelector((state: RootState) => state.editor.modalActivity);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!modalActivity) setModalOpen(false);
  }, [modalActivity]);

  const showModal = (content: ReactNode) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const contextValue: ModalContextType = {
    showModal,
    closeModal,
    modalOpen,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modalOpen && modalContent && <UniversalModal>{modalContent}</UniversalModal>}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const currentContext = useContext(ModalContext);

  if (!currentContext) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return currentContext;
};
