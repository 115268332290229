import EduboLogo from 'images/logo-main.svg?react';
import Dropdown from 'components/dropdown';
import { useSelector } from 'react-redux';
import type { RootState } from 'redux/store';
import Logout from 'components/nav/logout/Logout';
import { useNavigate } from 'react-router-dom';
import { isMacSafariFirefox } from 'utils/browserUtils';
import { useModal } from 'contexts/ModalProvider';
import ParentalAccess from './parental-access/ParentalAccess';
import Button from 'components/button/Button';
import ChangeClass from './change-class/ChangeClass';

type StudentLessonMenuNavProps = {
  lessonData: {
    _id: string;
    subject: string;
    teachingUnit: string;
    teacher: string;
    teachers_data_id: string;
    times: string[];
  } | undefined
};

const StudentLessonMenuNav = ({lessonData}: StudentLessonMenuNavProps) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const { showModal } = useModal();

  const isUserStudent = userInfo.role === 'student';

  const openPDFExport = () => {
    if (!lessonData) return;
    const url = `/pdf/student/${lessonData.teachers_data_id}/${lessonData._id}`;
  
    if (isMacSafariFirefox()) {
      setTimeout(() => {
        const newTab = window.open("", "_blank");
        if (newTab) { 
          newTab.location.href = url;
        } else {
          throw new Error("Failed to open new tab");
        }
      });
    } else {
      window.open(url, "_blank", "noopener noreferrer");
    }
  };

  return (
    <nav className="student-nav">
      <div className="student-left-menu">
        <EduboLogo className="student-logo" onClick={() => navigate(isUserStudent ? '/view-selection' : '/lesson-menu')} />
        <button type='button' hidden onClick={openPDFExport} id={"exportButton"} />
        <Dropdown
          label = "Soubor"
          disabled = {false}
          upPlacement = {false}
          noArrow = {false}
          defaultClasses = {true}
          menuMiddle = {true}
        >
          <Dropdown.Item
            disabled={!lessonData}
            onClick={() => openPDFExport()}
          >
            Exportovat do PDF
          </Dropdown.Item>
        </Dropdown>
        <Button
          onClick={() => navigate(location.pathname === '/calendar' ? '/subjects' : '/calendar')}
          typeOfButton="light"
          id={"changeViewButton"}>
          Přepnout pohled { location.pathname === '/calendar' ? '(Předměty)' : '(Kalendář)' }
        </Button>
        {isUserStudent &&
          <>
            <Button typeOfButton="light" onClick={() => showModal(<ParentalAccess/>)}>Rodičovský přístup</Button>
            <Button typeOfButton="light" onClick={() => showModal(<ChangeClass/>)}>Změnit třídu</Button>
          </>
        }
      </div>
      <div className="student-right-menu">
        <Logout/>
      </div>
    </nav>
  );
};

export default StudentLessonMenuNav;
