import Carousel from 'components/login/Carousel';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobilePasswordResetEmail from 'components/passwordReset/MobilePasswordResetEmail';
import PasswordResetEmailWindow from 'components/passwordReset/PasswordResetEmailWindow';
import { useWindowSize } from 'utils/useWindowSize';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/browserUtils';

const PasswordResetEmail = () => {
  const windowSize = useWindowSize();

  return (
    <section className="passwordReset">
      <Helmet>
        <title>EDUBO – Zapomenuté heslo</title>
        <link rel='canonical' href={`${websiteUrl}/change-password`}/>
      </Helmet>
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <PasswordResetEmailWindow />
          </>
        }
        mobile={
          <MobilePasswordResetEmail />
        }
      />
    </section>
  );
}

export default PasswordResetEmail;
