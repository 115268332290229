import AppBar from 'components/mobile-utils/AppBar';
import CalendarModal from './CalendarModal'
import { useModal } from 'contexts/ModalProvider';
import MobileCalendarLessons from './MobileCalendarLessons';
import Next from "images/student-lesson-menu-icons/next.svg?react";
import Previous from "images/student-lesson-menu-icons/previous.svg?react";
import { useLayoutEffect, useState } from 'react';
import type { CalendarData, LessonData, MobileCalendarLessonData } from 'types/StudentData';
import { optionsFrom, optionsTo } from 'utils/browserUtils';

type CalendarMenuWindowProps = {
  activeLesson: string | undefined;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  lessonProgress: string[] | undefined;
  calendarDayData: CalendarData[] | undefined;
  obtainCalendarData: () => Promise<void>;
};

const MobileCalendarMenu = ({ selectedDate, setSelectedDate, lessonProgress, calendarDayData, obtainCalendarData }: CalendarMenuWindowProps) => {
  const { showModal } = useModal();
  const [lessonDatas, setLessonDatas] = useState<LessonData[]>([]);

  useLayoutEffect(() => {
    if (!calendarDayData) return;
    const newLessonDatas: MobileCalendarLessonData[] = [];
    for (const lesson of calendarDayData) {
      let formattedTime = "";
      for (const time of lesson.days_of_usage) {
        const dateFrom = new Date(time.ts_from);
        if (dateFrom.getDate() !== selectedDate.getDate()) {
          if (dateFrom.getDate() > selectedDate.getDate()) {
            dateFrom.setDate(dateFrom.getDate() - 1);
            dateFrom.setHours(23, 59, 59, 0);
          } else continue;
        }
        const dateTo = new Date(time.ts_to);
        const formattedFrom = dateFrom.toLocaleString('cs-CZ', optionsFrom).replace(/,/g, '');
        const formattedTo = dateTo.toLocaleString('cs-CZ', optionsTo).replace(/,/g, '');
        formattedTime = `${formattedFrom} - ${formattedTo}`;
        break;
      }
      const newLessonData = {
        "_id": lesson._id,
        "name": lesson.name,
        "subject": lesson.subject,
        "teachingUnit": lesson.teaching_unit,
        "teacher": lesson.teachers_email,
        "teachers_data_id": lesson.teachers_data_id,
        "time": formattedTime
      };
      newLessonDatas.push(newLessonData);
    }
    setLessonDatas(newLessonDatas);
  }, [calendarDayData]);

  const previousDay = () => {
    const date = selectedDate;
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 1);
    
    if (date.getFullYear() !== prevDate.getFullYear() || date.getMonth() !== prevDate.getMonth()) {
      obtainCalendarData();
    }
    setSelectedDate(prevDate);
  };

  const nextDay = () => {
    const date = selectedDate;
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() + 1);
    
    if (date.getFullYear() !== prevDate.getFullYear() || date.getMonth() !== prevDate.getMonth()) {
      obtainCalendarData();
    }
    setSelectedDate(prevDate);
  };

  return (
    <>
      <AppBar />
      <div className="mobile-calendar-lesson-menu-header">
        <div className="mobile-calendar-lesson-menu-header-date">
          <Previous onClick={previousDay} className='change-date-buttons' />
          <span>{selectedDate.toLocaleDateString()}</span>
          <Next onClick={nextDay} className='change-date-buttons' />
        </div>
        <button
          type='button'
          className="mobile-calendar-lesson-menu-calendar-toggle"
          onClick={() => showModal(<CalendarModal selectedDate={selectedDate} setSelectedDate={setSelectedDate} />)}>
          Kalendář
        </button>
      </div>
      <div className="mobile-calendar-lesson-menu-content">
        <MobileCalendarLessons lessonProgress={lessonProgress} lessonDatas={lessonDatas} />
      </div>
    </>
  );
};

export default MobileCalendarMenu;
