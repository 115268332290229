export const CarouselPaths = [
  {
    path: "url('/slides/slide1.webp')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide2.webp')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide3.webp')",
    url: "https://www.mironet.cz/"
  }
];

export const MobileCarouselPaths = [
  {
    path: "url('/slides/slide1.webp')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide2.webp')",
    url: "https://www.mironet.cz/"
  },
  {
    path: "url('/slides/slide3.webp')",
    url: "https://www.mironet.cz/"
  }
];