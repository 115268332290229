type LessonPreviewProps = {
  activeLessonId?: string;
  activeTeachersId?: string;
};

const LessonPreview = ({activeLessonId, activeTeachersId}: LessonPreviewProps) => {
  return (
    <>
      {activeLessonId && activeTeachersId &&
        <iframe
          src={`/pdf/min/student/${activeTeachersId}/${activeLessonId}`}
          title='Lesson Preview'
          className='lesson-preview-iframe scrollbar'
          width={"100%"}
          height={"100%"}
        /> 
      }
    </>
  );
};

export default LessonPreview;
