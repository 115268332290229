import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';
import Eye from "images/eye.svg?react";
import EyeSlashed from "images/eye-slashed.svg?react";
import ArrowIcon from "images/arrow.svg?react";
import useRegistration from './useRegistration';
import AppBar from 'components/mobile-utils/AppBar';

const MobileRegistration = () => {
  const navigate = useNavigate();
  const {register, handleSubmit, errors, onSubmit, isSuccessfullySubmitted} = useRegistration();
  const [passwordShown, setPasswordShown] = useState(false);

/*   // Password toggle handler
  const togglePassword = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    setPasswordShown(!passwordShown);
  }; */

  return (
    <div className="w-full h-mobile-full">
      <AppBar />
      <section className="login_window_mobile w-full px-10 mt-16">
        <form className="data w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full relative">
            <div className='desc'>E-MAIL:</div>
            <input className='w-full' placeholder="Zadejte e-mail" id="email" maxLength={320} {...register("email")} />
            {errors.email && <FormErrorMessage message={errors.email.message} />}
          </div>
          <div className="w-full relative">
            <div className="flex flex-col relative">
              <div className='desc'>HESLO:</div>
              <input className='w-full' type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Zadejte heslo" id="password" {...register('password')} />
              {passwordShown ? 
                <Eye className="eye shown" onClick={() => setPasswordShown(!passwordShown)} style={{ top: "20px" }} />
              : 
                <EyeSlashed className="eye" onClick={() => setPasswordShown(!passwordShown)} style={{ top: "20px" }} />
              }
            </div>
            {errors.password && <FormErrorMessage message={errors.password.message} />}
          </div>

          <div className="w-full relative">
            <div className='desc'>HESLO ZNOVU:</div>
            <input className='w-full' type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Ověření hesla" id="confirmPassword" {...register('confirmPwd')} />
            {errors.confirmPwd && <FormErrorMessage message={errors.confirmPwd.message} />}
          </div>

          <div className="w-full relative">
            <div className='desc'>KLÍČ K REGISTRACI:</div>
            <input className='w-full' type="text" placeholder="Klíč k registraci" id="registrationKey" {...register('key')} />
            {errors.key && <FormErrorMessage message={errors.key?.message} />}
          </div>

          <div className="w-full relative gdpr-wrapper-check flex items-center">
            <input id="gdpr" type="checkbox" className="square-checkbox" {...register("gdpr")} />
            <label htmlFor="gdpr" className="ml-2">Souhlasím se <Link className='link-to-gdpr' to={"/gdpr"} target="_blank" rel="noopener noreferrer">zpracováním osobních údajů</Link></label>
            {errors.gdpr && <FormErrorMessage className="form-error-last" message={errors.gdpr?.message} />}
            {errors.apiError && <FormErrorMessage className="form-error-last" message={errors.apiError?.message} />}
          </div>

          <button type='submit' className="register mt-12">Registrovat se</button>
          {isSuccessfullySubmitted && (<p className="mt-3 ml-3 text-sm text-green-600 successfullySubmitted">Registrace proběhla v pořádku.</p>)}
          <div className='flex justify-center items-center mt-8'>
            <div className='back-to-login' onClick={() => navigate("/")}>
              <ArrowIcon className='back-to-login-arrow' />
              <Link to={"/"} className="back-to-login-text">Zpět na přihlášení</Link>
            </div>
          </div>

        </form>
      </section>
    </div>
  );
};

export const FormErrorMessage = ({ message, className }: { message?: string, className?: string }) => {
  return (
    <p className={`absolute inset-x-0 bottom-0 text-xs text-red-600 ${className}`}>{message}</p>
  );
}

export default MobileRegistration;
