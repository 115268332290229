import { useNavigate } from "react-router-dom";
import useResetPassword from './useResetPassword';
import AppBar from 'components/mobile-utils/AppBar';
import ArrowIcon from "images/arrow.svg?react";

const MobilePasswordResetEmail = () => {
  const navigate = useNavigate()
  const {registerEmail, requestToken, handleSumbitEmail, errorsEmail, isSuccessfullySubmitted} = useResetPassword();

  return (
    <div className="w-full h-mobile-full">
      <AppBar />
      <section className="password_reset_window_mobile w-full px-10">
        <form className="data w-full" onSubmit={handleSumbitEmail(requestToken)}>
          <div className="w-full relative">
            <div className='desc'>E-MAIL:</div>
            <input {...registerEmail("email")} className='w-full' maxLength={320} placeholder="Zadej e-mailovou adresu" />
            {errorsEmail.email && <p className="absolute mt-1 text-xs text-red-600">{errorsEmail.email.message}</p>}
            {errorsEmail.apiError && <p className={`absolute text-xs text-red-600 ${errorsEmail.email ? "mt-5" : "mt-1"}`}>{errorsEmail.apiError?.message}</p>}
            {isSuccessfullySubmitted && (<p className="mt-1 text-xs absolute text-green-600">E-mail byl úspěšně odeslán!</p>)}
          </div>
          <button type="submit" className="odeslat select-none">Odeslat</button>
        </form>
        <div className='flex justify-center items-center'>
          <button type='button' className='back-to-login' onClick={() => navigate("/")}>
            <ArrowIcon className='back-to-login-arrow' />
            <span className="back-to-login-text">Zpět na přihlášení</span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default MobilePasswordResetEmail;
