import { createSlice } from '@reduxjs/toolkit';
import type { iLessonMaterial } from 'types/LessonActivity';

type iModalActivity = {
  id: string;
  name: string;
  size: number;
  color: string;
  description: string;
  icons: string[];
  files: iLessonMaterial[] | [];
  urls: iLessonMaterial[] | [];
  gadgets: string[] | null;
  index: number;
  icon: string | null;
  club_url: string;
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState: {
    modalActivity: null as iModalActivity | null,
    sumOfRowsMins: 0 as number,
    notes: "",
    goal: "",
    curriculums: [] as string[],
    lessonName: "Nová hodina",
    lessonId: "",
    subjectId: "",
    teachersId: "",
    changedLesson: false,
    linearView: true,
    class: "",
    menu: "calendar",
    clipboard: ""
  },
  reducers: {
    setModalActivity: (state, action) => {
      state.modalActivity = action.payload
    },
    setSumOfRowsMins: (state, action) => {
      state.sumOfRowsMins = action.payload
    },
    setNotes: (state, action) => {
      state.notes = action.payload
    },
    setLessonName: (state, action) => {
      state.lessonName = action.payload
    },
    setGoal: (state, action) => {
      state.goal = action.payload
    },
    setCurriculums: (state, action) => {
      state.curriculums = action.payload
    },
    setLessonId: (state, action) => {
      state.lessonId = action.payload
    },
    setSubjectId: (state, action) => {
      state.subjectId = action.payload
    },
    setTeachersId: (state, action) => {
      state.teachersId = action.payload
    },
    setChangedLesson: (state, action) => {
      state.changedLesson = action.payload
    },
    setLinearView: (state, action) => {
      state.linearView = action.payload
    },
    setClass: (state, action) => {
      state.class = action.payload
    },
    setMenu: (state, action) => {
      state.menu = action.payload
    },
    setClipboard: (state, action) => {
      state.clipboard = action.payload
    }
  },
});

export const {
  setModalActivity,
  setSumOfRowsMins,
  setNotes,
  setLessonName,
  setGoal,
  setCurriculums,
  setSubjectId,
  setLessonId,
  setTeachersId,
  setChangedLesson,
  setLinearView,
  setClass,
  setMenu,
  setClipboard
} = editorSlice.actions;

export default editorSlice.reducer;
