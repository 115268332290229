import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModalActivity } from "redux/editorSlice";

type KeyboardShortcutsProps = {
  context: string;
};

export const KeyboardShortcuts = ({context}: KeyboardShortcutsProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent, context: string) => {
      event.preventDefault()
      event.stopPropagation()
      
      // ActivityModal
      if (context === "activityModal") {
        if (document.activeElement?.className.includes('public-DraftEditor-content')) return;
        if (document.activeElement?.className.includes('comment-textarea')) return;
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonActivityModal");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          dispatch(setModalActivity(null));
        } 
      }

      // LessonHeaderModal
      if (context === "lessonHeaderModal") {
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonLessonHeader");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonLessonHeader");
          if (closeButton) closeButton.click();
        }
      }

      // LessonMenuModal
      if (context === "lessoMenuModal") {
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonLessoMenuModal");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonLessoMenuModal");
          if (closeButton) closeButton.click();
        }
      }

      // DownloadModal
      if (context === "downloadModal") {
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonDownloadModal");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonDownloadModal");
          if (closeButton) closeButton.click();
        }
      }

      // LessonLengthModal
      if (context === "lessonLengthModal") {
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonLessonLengthModal");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonLessonLengthModal");
          if (closeButton) closeButton.click();
        }
      }

      // SaveModal
      if (context === "saveModal") {
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonSaveModal");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonSaveModal");
          if (closeButton) closeButton.click();
        }
      }

      // UploadModal
      if (context === "uploadModal") {
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonUploadModal");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonUploadModal");
          if (closeButton) closeButton.click();
        }
      }

      // ConfirmModal
      if (context === "confirmModal") {
        if (event.key === "Enter") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButtonConfirmModal");
          if (saveButton) saveButton.click();
        }
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonConfirmModal");
          if (closeButton) closeButton.click();
        }
      }

      // Material description modal
      if (context === "materialDescriptionModal") {
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonMaterialDescriptionModal");
          if (closeButton) closeButton.click();
        }
      }

      // Mobile student lesson menu calendar modal
      if (context === "mobileCalendarModal") {
        if (event.key === "Escape") {
          const closeButton = document.querySelector<HTMLButtonElement>("#closeButtonMobileCalendarModal");
          if (closeButton) closeButton.click();
        }
      }

      // Student Calendar Menu
      if (context === "studentCalendarMenu") {
        if (event.key === "Enter") {
          const openButton = document.querySelector<HTMLButtonElement>("#openButton");
          if (openButton) openButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "p") {
          const exportButton = document.querySelector<HTMLButtonElement>("#exportButton");
          if (exportButton) exportButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "Backspace") {
          const logoButton = document.querySelector<HTMLButtonElement>("#logoButton");
          if (logoButton) logoButton.click();
        }
        else if (event.key === "p") {
          const changeViewButton = document.querySelector<HTMLButtonElement>("#changeViewButton");
          if (changeViewButton) changeViewButton.click();
        }
      }

      // Student Subject MenulessonView
      if (context === "studentSubjectMenu") {
        if (event.key === "Enter") {
          const openButton = document.querySelector<HTMLButtonElement>("#openButton");
          if (openButton) openButton.click();
        }
        if (event.altKey && event.ctrlKey && event.key === "p") {
          const exportButton = document.querySelector<HTMLButtonElement>("#exportButton");
          if (exportButton) exportButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "Backspace") {
          const logoButton = document.querySelector<HTMLButtonElement>("#logoButton");
          if (logoButton) logoButton.click();
        }
        else if (event.key === "p") {
          const changeViewButton = document.querySelector<HTMLButtonElement>("#changeViewButton");
          if (changeViewButton) changeViewButton.click();
        }
      }

      // Lesson View
      if (context === "lessonView") {
        if (event.altKey && event.ctrlKey && event.key === "p") {
          const exportButton = document.querySelector<HTMLButtonElement>("#exportButton");
          if (exportButton) exportButton.click();
        }
        if (event.altKey && event.ctrlKey && event.key === "Backspace") {
          const logoButton = document.querySelector<HTMLButtonElement>("#logoButton");
          if (logoButton) logoButton.click();
        }
      }

      // Lesson Menu
      if (context === "lessonMenu") {
        if (document.activeElement?.className.includes('modal-input')) return;
        if (document.activeElement?.className.includes('desc-menu')) return;
        if (event.key === "Enter") {
          const editButton = document.querySelector<HTMLButtonElement>("#editButton");
          if (editButton) editButton.click();
        }
        else if (event.altKey && event.key === "z") {
          const viewDropdown = document.querySelector<HTMLButtonElement>("#viewDropdown");
          if (viewDropdown) viewDropdown.click();
        }
        else if (event.altKey && event.key === "n") {
          const uploadDropdown = document.querySelector<HTMLButtonElement>("#uploadDropdown");
          if (uploadDropdown) uploadDropdown.click();
        }
        else if (event.key === "z") {
          const changeViewButton = document.querySelector<HTMLButtonElement>("#changeViewButton");
          if (changeViewButton) changeViewButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "i") {
          const importLessonButton = document.querySelector<HTMLButtonElement>("#importLessonButton");
          if (importLessonButton) importLessonButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "e") {
          const exportLessonButton = document.querySelector<HTMLButtonElement>("#exportLessonButton");
          if (exportLessonButton) exportLessonButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "p") {
          const pdfExportButton = document.querySelector<HTMLButtonElement>("#pdfExportButton");
          if (pdfExportButton) pdfExportButton.click();
        }
      }

      // Editor
      if (context === "editor") {
        if (event.altKey && event.ctrlKey && event.key === "Backspace") {
          const logoButton = document.querySelector<HTMLButtonElement>("#logoButton");
          if (logoButton) logoButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "s") {
          const saveButton = document.querySelector<HTMLButtonElement>("#saveButton");
          if (saveButton) saveButton.click();
        }
        else if (event.altKey && event.ctrlKey && event.key === "p") {
          const pdfExportButton = document.querySelector<HTMLButtonElement>("#pdfExportButton");
          if (pdfExportButton) pdfExportButton.click();
        }
        else if (event.altKey && event.key === "s") {
          const fileDropdown = document.querySelector<HTMLButtonElement>("#fileDropdown");
          if (fileDropdown) fileDropdown.click();
        }
        else if (event.altKey && event.key === "p") {
          const pdfExportDropdown = document.querySelector<HTMLButtonElement>("#pdfExportDropdown");
          if (pdfExportDropdown) pdfExportDropdown.click();
        }
        else if (event.altKey && event.key === "v") {
          const searchDropdown = document.querySelector<HTMLButtonElement>("#searchDropdown");
          if (searchDropdown) searchDropdown.click();
        }
        else if (event.altKey && event.key === "l") {
          const lessonLengthDropdown = document.querySelector<HTMLButtonElement>("#lessonLengthDropdown");
          if (lessonLengthDropdown) lessonLengthDropdown.click();
        }
        else if (event.altKey && event.key === "k") {
          const controlButton = document.querySelector<HTMLButtonElement>("#controlButton");
          if (controlButton) controlButton.click();
        }
      }
    };

    const eventListener = (event: KeyboardEvent) => handleKeyUp(event, context);

    document.addEventListener("keyup", eventListener);

    return () => {
      document.removeEventListener("keyup", eventListener);
    };
  }, []);

  return null;
};