import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from 'react';
import axiosRequest from 'utils/axios';

type ResetPasswordResponse = {
  message: string;
};

type FormEmail = {
  email: string;
  apiError?: string;
};

type FormPassword = {
  newPassword: string;
  confirmPassword: string;
  apiError?: string;
  token?: string;
};

// password reset logic hook
export default () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState<boolean>(false);
  
  const formSchemaEmail = yup.object().shape({
    email: yup.string()
      .email('E-mail není ve platném formátu!')
      .max(320, "E-mail nesmí být delší než 320 znaků!")
      .required('Toto pole je povinné!')
  });

  const formSchemaPassword = yup.object().shape({
    newPassword: yup.string()
      .required("Toto pole je povinné!")
      .min(6, "Heslo musí být minimálně 6 znaků dlouhé!")
      .max(100, "Heslo nesmí být delší než 100 znaků!"),
    confirmPassword: yup.string()
      .required("Toto pole je povinné!")
      .oneOf([yup.ref("newPassword")], "Hesla se neshodují!")
  });

  const {setError: setErrorEmail, register: registerEmail, handleSubmit: handleSumbitEmail, formState: { errors: errorsEmail }} = useForm<FormEmail>({ 
    resolver: yupResolver(formSchemaEmail)
  });

  const {setError: setErrorPassword, register: registerPassword, handleSubmit: handleSubmitPassword, formState: { errors: errorsPassword }} = useForm<FormPassword>({ 
    resolver: yupResolver(formSchemaPassword)
  });

  const resetPassword = async (data: FormPassword) => {
    const response = await axiosRequest<ResetPasswordResponse>("POST", "/api/reset-password", {
      token: searchParams.get("token"),
      new_password: data.newPassword
    });
    if (!response.success) {
      if (response.errorMessage.includes("This token doesn't exist!")) {
        setErrorPassword("apiError", {
          type: "server",
          message: 'Tento token neexistuje!',
        });
      } else if (response.errorMessage.includes("Token already expired!")) {
        setErrorPassword("apiError", {
          type: "server",
          message: 'Platnost tokenu vypršela!',
        });
      } else if (response.errorMessage.includes("This token was already used!")) {
        setErrorPassword("apiError", {
          type: "server",
          message: 'Tento token byl již použit!',
        });
      } else {
        setErrorPassword('apiError', {
          type: "server",
          message: "Někde nastala chyba zkuste to znovu!",
        });
      }
      return;
    }
    setIsSuccessfullySubmitted(true);
    setTimeout(() => {
      navigate("/");
    }, 750);
  };

  const requestToken = async (data: FormEmail) => {
    const response = await axiosRequest("POST", "/api/request-token", {
      email: data.email,
      hostname: window.location.origin
    })
    if (!response.success) {
      if (response.errorMessage.includes("This email is not registered!")) {
        setErrorEmail("apiError", {
          type: "server",
          message: 'Tento email není zaregistrován!',
        });
      } else {
        setErrorEmail('apiError', {
          type: "server",
          message: "Někde nastala chyba zkuste to znovu!",
        });
      }
      return;
    }
    setIsSuccessfullySubmitted(true);
  };
  
  return {
    registerEmail,
    registerPassword,
    handleSumbitEmail,
    handleSubmitPassword,
    errorsEmail,
    errorsPassword,
    isSuccessfullySubmitted,
    resetPassword,
    requestToken
  };
};
