import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux";
import { removeUser } from 'redux/authSlice';
import axiosRequest from "utils/axios";
import { useSnackbar } from "contexts/SnackbarProvider";
import Button from 'components/button/Button';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openErrorSnackbar } = useSnackbar();

  const handleLogout = async () => {
    const response = await axiosRequest("GET", "/api/logout");
    if (!response.success) {
      openErrorSnackbar("Odhlášení se nezdařilo!");
      return;
    }
    dispatch(removeUser());
    navigate("/");
  };

  return (
    <Button
      onClick={handleLogout}
      typeOfButton="light"
    >
      Odhlásit se
    </Button>
  );
};

export default Logout;
