import { useState, useEffect } from 'react';
import EduboSmall from 'images/edubo_small.svg?react';
import ArrowIcon from "images/arrow.svg?react";
import SideBar from './SideBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { RootState } from 'redux/store';

const AppBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menu = useSelector((state: RootState) => state.editor.menu);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    if (open) setOpen(false);
  }, [location]);

  const handleButtonClick = () => {
    setOpen(!open);
  };

  const navigateToViewSelection = () => {
    if (window.location.pathname.includes("/lesson-view")) navigate(menu === 'calendar' ? '/calendar' : '/subjects');
    else navigate('/view-selection');
  };

  return (
    <div className={"w-full z-50 sticky top-0 app-bar"} style={{ backgroundColor: "#8007AA" }}>
      <div className="flex items-center justify-between p-2">
        <EduboSmall className="h-6 w-6 mr-2 cursor-pointer" onClick={navigateToViewSelection} />
        <button type='button' className="open-sidebar" onClick={handleButtonClick} aria-label='Otevřít menu'>
          <ArrowIcon className={`menu-arrow ${open ? "open" : ""}`}/>
        </button>
      </div>
      <SideBar currentPath={location.pathname} open={open} onClose={handleButtonClick} />
    </div>
  );
};

export default AppBar;
