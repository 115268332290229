import type { DesktopCalendarLessonData } from "types/StudentData";

type CalendarInfoMenuProps = {
  lessonData: DesktopCalendarLessonData;
  activeLesson: string | undefined;
};

const CalendarInfoMenu = ({lessonData, activeLesson}: CalendarInfoMenuProps) => {

  return (
    <div className="gap-1 flex flex-col">
      <div><span className="student-calendar-heading-small">Předmět: </span>{lessonData.subject}</div>
      <div><span className="student-calendar-heading-small">Výukový celek: </span>{lessonData.teachingUnit}</div>
      <div><span className="student-calendar-heading-small">Název hodiny: </span>{activeLesson}</div>
      <div><span className="student-calendar-heading-small">Vyučující: </span>{lessonData.teacher}</div>
      <div className="flex flex-row">
        <div className="w-min mr-1">
          <span className="student-calendar-heading-small">Čas: </span>
        </div>
        <div className="flex-col">
          {lessonData.times.map((time, index) => {
            return <div key={index}>{time}</div>
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarInfoMenu;
