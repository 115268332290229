import { useEffect } from 'react';
import StudentSelectionMenu from 'components/student/lesson-menu/StudentSelectionMenu';
import StudentInfoMenu from 'components/student/lesson-menu/StudentInfoMenu';
import StudentMaterialsOverview from 'components/student/materials-overview/StudentMaterialsOverview';
import StudentLessonMenuNav from 'components/student/nav/StudentLessonMenuNav';
import useLessonMenuLogic from 'components/student/lesson-menu/hooks/useLessonMenuLogic';
import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';
import type { File, FullMaterial } from 'types/StudentData';
import axiosRequest from 'utils/axios';
import { useSnackbar } from 'contexts/SnackbarProvider';

type MaterialsResponse = {
  files: File[];
  urls: File[];
};

export enum MenuTypes {
  SUBJECT = 2,
  TEACHING_UNIT = 3,
  LESSON = 4
}

const StudentLessonMenuWindow = () => {
  const {
    // Variables
    subjectNames,
    selectionSubjectNames,
    activeSubject,
    teachingUnitsNames,
    selectionTeachingUnitsNames,
    lessonNames,
    selectionLessons,
    activeLesson,
    lessonData,
    savedMaterials,
    studentsEmail,
    studentsClass,
    lessonProgress,
    lessonRating,
    lessons,
    // Functions
    setActiveSubject,
    setActiveTeachingUnit,
    setActiveLesson,
    setSavedMaterials,
    setSelectionSubjectNames,
    setSelectionTeachingUnitsNames,
    setSelectionLessons,
    setSubjectsInUserData,
    setTeachingUnitsInUserData,
    setLessonsInUserData,
    obtainLessons,
    handleOpenButton,
    rateLessonMaterials
  } = useLessonMenuLogic();
  const { openErrorSnackbar } = useSnackbar();

  // Update "Uložené materiály" section when lessonData changes
  useEffect(() => {
    if (!activeLesson || !lessonData) return;
    getMaterials();
  }, [activeLesson, lessonData]);

  // Get materials of the lesson from API endpoint
  const getMaterials = async () => {
    if (!lessonData) return;
    const lesson = lessons.find((lesson) => lesson.name === activeLesson);
    if (!lesson) return;
    const response = await axiosRequest<MaterialsResponse>("GET", `/api/lesson/${lesson._id}/${lesson.teachers_data_id}/student-materials`);
    if (!response.success) {
      console.error(response.errorMessage);
      if (response.errorMessage.includes("Lesson not found")) openErrorSnackbar("Hodina nebyla nalezena!");
      else openErrorSnackbar("Nepodařilo se načíst materiály!");
      return;
    }
    const newMaterials: FullMaterial[] = [];
    for (const file of response.data.files) {
      newMaterials.push({
        name: file.name,
        type: "file",
        description: file.description,
        url_to_file: file.url_to_file
      });
    }
    for (const url of response.data.urls) {
      newMaterials.push({
        name: url.name,
        type: "url",
        description: url.description,
      });
    }
    setSavedMaterials(newMaterials);
  };

  return (
    <section className="student-lesson-menu">
      <KeyboardShortcuts context="studentSubjectMenu"/>
      {/* Navbar */}
      <StudentLessonMenuNav
        lessonData={lessonData}
      />
      {/* Left column */}
      <div className="student-left-column">
        {/* Name of the students class */}
        <div className='student-box-heading'>
          <label className="student-heading-small">{studentsEmail}</label>
          <label className="student-heading-medium">{studentsClass}</label>
        </div>
        {/* Menu for selecting "předmět" */}
        <div className="student-select-box">
          <div className='student-select-box-heading'>
            <label className="student-heading-medium">Předměty</label>
          </div>
          <div className='student-elements-box'>
            <StudentSelectionMenu
              list={subjectNames}
              setList={setSubjectsInUserData}
              selectionList={selectionSubjectNames}
              setSelectionList={setSelectionSubjectNames}
              setActiveList={setActiveSubject}
              subjectSelect={false}
              obtainLessons={obtainLessons}
              lessonProgress={lessonProgress}
              activeSubject={activeSubject}
              mobile={false}
              handleOpenButton={handleOpenButton}
              type={MenuTypes.SUBJECT}
            />
          </div>
        </div>
        {/* Menu for selecting "výukové celky" */}
        <div className="student-select-box">
          <div className='student-select-box-heading'>
            <label className="student-heading-medium">Výukové celky</label>
          </div>
          <div className='student-elements-box'>
            <StudentSelectionMenu
              list={teachingUnitsNames}
              setList={setTeachingUnitsInUserData}
              selectionList={selectionTeachingUnitsNames}
              setSelectionList={setSelectionTeachingUnitsNames}
              setActiveList={setActiveTeachingUnit}
              subjectSelect={true}
              obtainLessons={obtainLessons}
              activeSubject={activeSubject}
              lessonProgress={lessonProgress}
              mobile={false}
              handleOpenButton={handleOpenButton}
              type={MenuTypes.TEACHING_UNIT}
            />
          </div>
        </div>
      </div>
      {/* Middle column */}
      <div className="student-middle-column">
        {/* Content for middle column */}
        {/* Menu for selecting "hodiny" */}
        <div className="student-lection">
          <div className='student-select-box-heading'>
            <label className="student-heading-big">Hodiny</label>
          </div>
          <div className='student-select-box-body'>
            <StudentSelectionMenu
              list={lessonNames}
              setList={setLessonsInUserData}
              selectionList={selectionLessons}
              setSelectionList={setSelectionLessons}
              setActiveList={setActiveLesson}
              subjectSelect={false}
              lessonProgress={lessonProgress}
              type={MenuTypes.LESSON}
              handleOpenButton={handleOpenButton}
              obtainLessons={obtainLessons}
              activeSubject={activeSubject}
              mobile={false}
            />
          </div>
        </div>
        <StudentMaterialsOverview materialsList={savedMaterials} rateLessonMaterials={rateLessonMaterials} lessonRating={lessonRating} />
      </div>
      {/* Right column */}
      <div className="student-right-column">
        {/* Content for right column */}
        <div className='flex flex-col gap-5'>
          <label className="student-heading-medium">Popis hodiny:</label>
          {lessonData ? (<StudentInfoMenu lessonData={lessonData} activeLesson={activeLesson} />) : null}
        </div>
        <div className="student-info-buttons">
          <button type='button' className="student-open" disabled={!activeLesson} id={"openButton"} onClick={() => handleOpenButton(activeLesson)}>Otevřít</button>
        </div>
      </div>
    </section>
  );
};

export default StudentLessonMenuWindow;
