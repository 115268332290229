import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Eye from "images/eye.svg?react";
import EyeSlashed from "images/eye-slashed.svg?react";
import EduboIcon from "images/edubo.svg?react";
import useLogin from './useLogin';
import InfoFooter from 'components/info-pages/InfoFooter';

const LoginWindow = () => {
  const navigate = useNavigate();
  const { register, onSubmit, handleSubmit, errors } = useLogin();
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <section className="login_window">
      <div className='login_container'>
        <EduboIcon className='logo'/>
        <form className="data" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <div className='desc'>E-MAIL:</div>
            <input placeholder="Zadejte e-mail" maxLength={320} {...register("email")} />
            {errors.email && <p className="absolute mt-1 text-sm text-red-600">{errors.email.message}</p>}
          </div>
          <div className="w-full">
            <div className="flex flex-col relative">
              <div className='desc'>HESLO:</div>
              <input type={passwordShown ? "text" : "password"} maxLength={100} placeholder="Zadejte heslo" {...register("password")} />
              {passwordShown ? 
                <Eye className="eye shown" onClick={() => setPasswordShown(!passwordShown)} />
              : 
                <EyeSlashed className="eye" onClick={() => setPasswordShown(!passwordShown)} />
              }
            </div>
            {errors.password && <p className="absolute mt-1 text-sm text-red-600">{errors.password.message}</p>}
            {errors.apiError && <p className="absolute mt-1 text-sm text-red-600">{errors.apiError?.message}</p>}
          </div>
          <button type="submit" className="select-none">Přihlásit se</button>
          <button type="button" className="register" onClick={() => navigate("/registration")}>Registrovat se</button>
          <div className="flex items-end justify-center remember-me">
            <input id="remember" type="checkbox" className="square-checkbox" {...register("remember")} />
            <label htmlFor="remember" className="ml-2">ZAPAMATOVAT SI PŘIHLÁŠENÍ</label>
          </div>
          <div className='flex items-center justify-center forgotten-password hover:underline'>
            <Link to="/change-password">ZAPOMENUTÉ HESLO</Link>
          </div>
        </form>
      </div>
      <InfoFooter page={"login"}/>
    </section>
  );
}

export default LoginWindow;
