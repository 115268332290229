import LoginWindow from 'components/login/LoginWindow';
import Carousel from 'components/login/Carousel';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import MobileLogin from 'components/login/MobileLogin';
import { useWindowSize } from 'utils/useWindowSize';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/browserUtils';

const Login = () => {
  const windowSize = useWindowSize();

  return (
    <section className="login">
      <Helmet>
        <title>EDUBO – Inovativní plánování výuky pro učitele a studenty</title>
        <link rel='canonical' href={`${websiteUrl}/`}/>
      </Helmet>
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <LoginWindow />
          </>
        }
        mobile={
          <MobileLogin />
        }
      />
    </section>
  );
};

export default Login;
