import { useModal } from 'contexts/ModalProvider';

type UniversalModalProps = {
  children: React.ReactNode;
};

const UniversalModal = ({children}: UniversalModalProps) => {
  const { closeModal } = useModal();

  const handleOutsideContentClick = () => {
    const activeElement = document.activeElement;
    if (activeElement && activeElement.tagName === 'INPUT') return;
    if (activeElement && activeElement.tagName === 'TEXTAREA') return;
    if (activeElement?.className.includes('DraftEditor')) return;
    const saveButtonActivityModal = document.querySelector<HTMLButtonElement>("#saveButtonActivityModal");
    if (saveButtonActivityModal) saveButtonActivityModal.click();
    closeModal();
  };

  return (
    <div className="modal" onClick={handleOutsideContentClick}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default UniversalModal;
