import { useModal } from 'contexts/ModalProvider';
import MaterialDescriptionModal from "../material-description-modal/MaterialDescriptionModal";
import Unused from "images/student-lesson-menu-icons/unused.svg?react";
import Downvote from "images/student-lesson-menu-icons/downvote.svg?react";
import Neutral from "images/student-lesson-menu-icons/neutral.svg?react";
import Upvote from "images/student-lesson-menu-icons/upvote.svg?react";
import Url from "images/student-lesson-menu-icons/url.svg?react";
import File from "images/student-lesson-menu-icons/file.svg?react";
import Redirect from "images/student-lesson-menu-icons/redirect.svg?react";
import { useSelector } from "react-redux";
import type { RootState } from 'redux/store';
import type { FullMaterial } from 'types/StudentData';

type StudentMaterialOverviewProps = {
  materialsList: FullMaterial[] | undefined;
  rateLessonMaterials: (rating: string) => void;
  lessonRating: string | undefined;
};

const StudentMaterialOverview = ({materialsList, rateLessonMaterials, lessonRating}: StudentMaterialOverviewProps) => {
  const { showModal } = useModal();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  return (
    <div className="student-materials-overview">
      <div className="materials-overview-heading">
        <label className="student-heading-medium">Přehled materiálů k hodině:</label>
        {userInfo.role === 'student' && (
          <div className="rating-buttons">
            <button
              type='button'
              onClick={() => {rateLessonMaterials("upvote")}}
              disabled={lessonRating === 'upvote'}
            >
              <Upvote className={`rating-button ${lessonRating === 'upvote' ? 'selected' : ''}`}/>
            </button>
            <button
              type='button'
              onClick={() => {rateLessonMaterials("neutral")}}
              disabled={lessonRating === 'neutral'}
            >
              <Neutral className={`rating-button ${lessonRating === 'neutral' ? 'selected' : ''}`}/>
            </button>
            <button
              type='button'
              onClick={() => {rateLessonMaterials("downvote")}}
              disabled={lessonRating === 'downvote'}
            >
              <Downvote className={`rating-button ${lessonRating === 'downvote' ? 'selected' : ''}`}/>
            </button>
            <button
              type='button'
              onClick={() => {rateLessonMaterials("unused")}}
              disabled={lessonRating === 'unused'}
            >
              <Unused className={`rating-button ${lessonRating === 'unused' ? 'selected' : ''}`}/>
            </button>
          </div>
        )}
      </div>
      <div className="materials">
        {materialsList?.map((material, index) => (
            <div className="materials-item-wrapper" key={index}>
              {material.type === "file" ?
                <>
                  <File className="material-type-icon" />
                  <div className="materials-item" onClick={() => showModal(<MaterialDescriptionModal description={material.description} url_to_file={material.url_to_file} />)}>
                    <span className="material-name">
                      {material.name}
                    </span>
                  </div>
                </>
              : 
                <>
                  <Url className="material-type-icon" />
                  <div className="materials-item" onClick={() => showModal(<MaterialDescriptionModal description={material.description} />)}>
                    <span className="material-name">
                      {material.name}
                    </span>
                    <a
                      href={material.name.startsWith('http') ? material.name : `//${material.name}`}
                      target="_blank"
                      rel="noreferrer"
                      className="url-redirect"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Redirect/>
                    </a>
                  </div>
                </>
              }
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default StudentMaterialOverview;
