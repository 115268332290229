import { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from 'redux/store';
import { setLessonId, setMenu, setTeachersId } from 'redux/editorSlice';
import type {
  iStudentSubjectsMenuData,
  iStudentSubject,
  iStudentTeachingUnit,
  iStudentLesson,
  iStudentLessonMenuIndexes,
  FullMaterial,
  LessonMenuData
} from 'types/StudentData';
import axiosRequest from 'utils/axios';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { optionsFrom, optionsTo } from 'utils/browserUtils';

type LessonMaterialRatingResponse = {
  rating: string;
};

export default () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openErrorSnackbar } = useSnackbar();
  const classId = useSelector((state: RootState) => state.auth.userInfo.class_id);
  const studentsEmail = useSelector((state: RootState) => state.auth.userInfo.email);
  const studentsClass = useSelector((state: RootState) => state.editor.class);

  // Subject variables
  const [subjectsData, setSubjectsData] = useState<iStudentSubjectsMenuData>();
  const [subjectNames, setSubjectNames] = useState<string[]>([]);
  const [selectionSubjectNames, setSelectionSubjectNames] = useState<string[]>([]);
  const [activeSubject, setActiveSubject] = useState<string>('');

  // Teaching units variables
  const [teachingUnitsNames, setTeachingUnitNames] = useState<string[]>([]);
  const [selectionTeachingUnitsNames, setSelectionTeachingUnitsNames] = useState<string[]>([]);
  const [activeTeachingUnit, setActiveTeachingUnit] = useState<string>('');

  // Lesson variables
  const [lessonNames, setLessonNames] = useState<string[]>([]);
  const [selectionLessons, setSelectionLessons] = useState<string[]>([]);
  const [activeLesson, setActiveLesson] = useState<string>('');
  const [lessons, setLessons] = useState<iStudentLesson[] | []>([]);
  const [lessonProgress, setLessonProgress] = useState<string[]>([]);
  const [lessonRating, setLessonRating] = useState<string>("");

  // Other variables
  const [lessonData, setLessonData] = useState<LessonMenuData | undefined>();
  const [savedMaterials, setSavedMaterials] = useState<FullMaterial[]>([]);

  // Obtain user data when the page loads
  useEffect(() => {
    obtainSubjectsData();
    dispatch(setMenu("subject"));
  }, []);

  useLayoutEffect(() => {
    if (!subjectsData) return;
    setNamesForDisplay(subjectsData.subjects);
  }, [subjectsData]);

  // Update the lesson menu when the user clicks on an unselected subject
  useLayoutEffect(() => {
    updateSelections(selectionSubjectNames, setSelectionSubjectNames, subjectNames, activeSubject);
    obtainTeachingUnits(activeSubject);
  }, [activeSubject]);

  // Update the lesson menu when the user clicks on an unselected teaching unit
  useLayoutEffect(() => {
    updateSelections(selectionTeachingUnitsNames, setSelectionTeachingUnitsNames, teachingUnitsNames, activeTeachingUnit);
    obtainLessons(activeSubject, activeTeachingUnit);
  }, [activeTeachingUnit]);

  // When a lesson is selected -> Fetches lesson data and loads it into the page
  useLayoutEffect(() => {
    setLessonData(undefined);
    updateSelections(selectionLessons, setSelectionLessons, lessonNames, activeLesson);
    const lesson = lessons.find((lesson) => lesson.name === activeLesson);
    if (!lesson) return;
    const times: string[] = [];
    for (const time of lesson.days_of_usage) {
      const dateFrom = new Date(time.ts_from);
      const dateTo = new Date(time.ts_to);
      const formattedFrom = dateFrom.toLocaleString('cs-CZ', optionsFrom).replace(/,/g, '');
      const formattedTo = dateTo.toLocaleString('cs-CZ', optionsTo).replace(/,/g, '');
      times.push(`${formattedFrom} - ${formattedTo}`);
    }
    setLessonData({
      _id: lesson._id,
      subject: activeSubject,
      teachingUnit: activeTeachingUnit,
      teacher: lesson.teachers_email,
      teachers_data_id: lesson.teachers_data_id,
      times: times
    });

    getSetLessonMaterialsRating();
  }, [activeLesson]);

  // Updates the list of lesson names when the list of lessons changes
  useLayoutEffect(() => {
    const valuesLessons = lessons.map(object => object.name);
    if (valuesLessons[0] !== "") {
      setLessonNames(valuesLessons);
      setActiveLesson(valuesLessons[0]);
    } else {
      setLessonNames([]);
    }
    getLessonProgress();
  }, [lessons]);

  // Sets subjects in subjects data
  const setSubjectsInSubjectsData = (newSubjectNames: string[]) => {
    if (!subjectsData) {
      console.error("SubjectsData are missing!");
      return;
    }
    const newSubjects: iStudentSubject[] = newSubjectNames
      .map((name) => subjectsData.subjects.find((subject) => subject.name === name))
      .filter(Boolean) as iStudentSubject[];
    setSubjectNames(newSubjectNames);
    subjectsData.subjects = newSubjects;
  };

  // Sets teaching units in subjects data
  const setTeachingUnitsInSubjectsData = (newTeachingUnitNames: string[]) => {
    if (!subjectsData) {
      console.error('SubjectsData are missing!');
      return;
    }
    const indexes = getActiveItemsIndexes();
    const activeSubject = subjectsData.subjects[indexes.activeSubjectIndex];
    if (!activeSubject) {
      console.error('Active subject is missing!');
      return;
    }
    const newTeachingUnits: iStudentTeachingUnit[] = newTeachingUnitNames
      .map(name => activeSubject.teaching_units.find(unit => unit.name === name))
      .filter(Boolean) as iStudentTeachingUnit[];
    setTeachingUnitNames(newTeachingUnitNames);
    activeSubject.teaching_units = newTeachingUnits;
  };

  // Sets lessons in subjects data
  const setLessonsInSubjectsData = (newLessonNames: string[]) => {
    if (!subjectsData) {
      console.error('SubjectsData are missing!');
      return;
    }
    const indexes = getActiveItemsIndexes();
    const activeTeachingUnit = subjectsData
      .subjects?.[indexes.activeSubjectIndex]?.teaching_units?.[indexes.activeTeachingUnitIndex];
    if (!activeTeachingUnit) {
      console.error('Active teaching unit is missing!');
      return;
    }
    const newLessons: iStudentLesson[] = newLessonNames
      .map(name => activeTeachingUnit.lessons.find(lesson => lesson.name === name))
      .filter(Boolean) as iStudentLesson[];
    setLessonNames(newLessonNames);
    activeTeachingUnit.lessons = newLessons;
  };

  // Fetches subjects data
  const obtainSubjectsData = async () => {
    const response = await axiosRequest<iStudentSubjectsMenuData>('GET', `/api/student/subjects/${classId}`);
    if (!response.success) {
      console.error(response.errorMessage);
      if (response.errorMessage.includes("Class not found")) openErrorSnackbar("Třída nebyla nalezena!");
      else openErrorSnackbar("Nepodařilo se načíst data o předmětech!");
      return;
    }
    setSubjectsData(response.data);
  };

  // Sets lesson names and lessons list based on active subject and teaching unit 
  const obtainLessons = (activeSubject: string, activeTeachingUnit: string) => {
    if (!subjectsData || activeSubject === '') return;
    const SelectedSubject = subjectsData.subjects.filter((obj) => obj.name === activeSubject);
    const mySubject = JSON.parse(JSON.stringify(SelectedSubject)) as iStudentSubject[];

    if (!mySubject) {
      console.error('Selected subject is missing!');
      return;
    }

    const teachingUnit: iStudentTeachingUnit[] = mySubject[0].teaching_units.filter((obj) => obj.name === activeTeachingUnit);

    if (!teachingUnit[0]) return setLessons([]);
    if (!teachingUnit[0].lessons) teachingUnit[0].lessons = [];
    if (teachingUnit[0].lessons.length < 1) return setLessons([]);

    const orderedLessons = teachingUnit[0].lessons.sort((a, b) => {
      const aMinTsFrom = Math.min(...a.days_of_usage.map((day) => new Date(day.ts_from).getTime()));
      const bMinTsFrom = Math.min(...b.days_of_usage.map((day) => new Date(day.ts_from).getTime()));
      return aMinTsFrom - bMinTsFrom;
    });

    const counts: { [key: string]: number } = {};
    for (const item of orderedLessons) {
      if (counts[item.name]) {
        counts[item.name]++;
        item.name = `${item.name} (${counts[item.name]})`;
      } else {
        counts[item.name] = 1;
      }
    }

    setLessons(orderedLessons);
  }

  // Sets and sorts the unique subject names for display
  const setNamesForDisplay = (subjects: iStudentSubject[]) => {
    const subjectNames: string[] = [];
    for (const subject of subjects) {
      if (!subjectNames.includes(subject.name)) subjectNames.push(subject.name);
    }
    setSubjectNames(subjectNames.sort((a, b) => a.localeCompare(b, 'cs')));
    setActiveSubject(subjectNames.sort((a, b) => a.localeCompare(b, 'cs'))[0]);
  };

  // Obtains and sets the unique teaching unit names for a given subject
  const obtainTeachingUnits = (subjectName: string) => {
    if (!subjectName) return setTeachingUnitNames([]);
    if (!subjectsData) return;
    const activeSubject = subjectsData.subjects.find((value) => value.name === subjectName);
    if (!activeSubject) {
      setLessons([]);
      setTeachingUnitNames([]);
      return;
    }
    const activeTeachingUnitNames: string[] = [];
    for (const value of activeSubject.teaching_units) {
      if (activeTeachingUnitNames.includes(value.name)) {
        console.error(`Teaching units contains duplicated values: teachingUnitNames = ${activeTeachingUnitNames}, value: ${value.name}`);
        return;
      }
      activeTeachingUnitNames.push(value.name);
    }
    setTeachingUnitNames(activeTeachingUnitNames);
    setActiveTeachingUnit(activeTeachingUnitNames[0]);
  };

  // Retrieves the indexes of active items (subject, teaching unit, and lesson)
  const getActiveItemsIndexes = () => {
    const indexes: iStudentLessonMenuIndexes = {
      activeSubjectIndex: 0,
      activeTeachingUnitIndex: 0,
      activeLessonIndex: 0
    };
    if (!subjectsData) return indexes;

    // Active subject
    if (!activeSubject) return indexes;
    indexes.activeSubjectIndex = subjectsData.subjects.findIndex((value) => value.name === activeSubject);

    // Active teaching unit
    if (!activeTeachingUnit) return indexes
    indexes.activeTeachingUnitIndex = subjectsData.subjects[indexes.activeSubjectIndex]
      .teaching_units.findIndex((value) => value.name === activeTeachingUnit);

    // Active lesson
    if (!activeLesson) return indexes
    indexes.activeLessonIndex = subjectsData.subjects[indexes.activeSubjectIndex]
      .teaching_units[indexes.activeTeachingUnitIndex]
      .lessons.findIndex((value) => value.name === activeLesson);

    return indexes;
  };

  // Updates the selection state of given selection list (subjectNames/teachingUnitsNames/lessonNames) based on the given active item and list
  const updateSelections = (
    selection: Array<string>,
    setSelection: React.Dispatch<React.SetStateAction<string[]>>,
    list?: Array<string>,
    active?: string
  ) => {
    const newSelection = new Array(selection.length);
    newSelection.fill("unselected");
    if (list && active) {
      const indexOfActiveItem = list.indexOf(active);
      newSelection[indexOfActiveItem] = "selected";
    }
    setSelection(newSelection);
  };

  const handleOpenButton = (clickedLesson: string) => {
    const lesson = lessons.find(lesson => lesson.name === clickedLesson);
    if (!lesson) return;
    dispatch(setLessonId(lesson._id));
    dispatch(setTeachersId(lesson.teachers_data_id));
    navigate('/lesson-view');
  };

  const getLessonProgress = () => {
    if (!lessons) return;
    const currentDay = new Date();
    const newProgress: string[] = [];
    for (let i = 0; i < lessons.length; i++) {
      const lesson = lessons[i];
      for (let j = 0; j < lesson.days_of_usage.length; j++) {
        const time = lesson.days_of_usage[j];
        const tsFrom = new Date(time.ts_from);
        const tsTo = new Date(time.ts_to);
        if (tsFrom > currentDay) {
          newProgress.push("pending");
          continue;
        }
        if (tsFrom < currentDay && tsTo > currentDay) {
          newProgress.push("ongoing");
          continue;
        }
        if (tsTo < currentDay) {
          newProgress.push("done");
        }
      }
    }
    setLessonProgress(newProgress);
  };

  const rateLessonMaterials = async (rating: string) => {
    const lessonId = lessons.find((lesson) => lesson.name === activeLesson)?._id;
    if (!lessonId) {
      console.error('Lesson ID is missing!');
      return;
    }
    const response = await axiosRequest('POST', `/api/lesson/${lessonId}/rate`,
      { rating }
    );
    if (!response.success) {
      console.error(response.errorMessage);
      if (response.errorMessage.includes('Invalid rating')) openErrorSnackbar('Neplatné hodnocení!');
      else if (response.errorMessage.includes('Lesson not found')) openErrorSnackbar('Hodina nebyla nalezena!');
      else if (response.errorMessage.includes('You are not in this class!')) openErrorSnackbar('Nejste studentem této třídy!');
      else openErrorSnackbar('Nepodařilo se uložit hodnocení');
      return;
    }
    setLessonRating(rating);
  };

  const getSetLessonMaterialsRating = async () => {
    const lessonId = lessons.find((lesson) => lesson.name === activeLesson)?._id;
    if (!lessonId) {
      console.error('Lesson ID is missing!');
      return;
    }
    const response = await axiosRequest<LessonMaterialRatingResponse>('GET', `/api/student/lesson/${lessonId}/rating`);
    if (!response.success) {
      console.error(response.errorMessage);
      setLessonRating("");
      if (response.errorMessage.includes('Lesson not found')) openErrorSnackbar('Hodina nebyla nalezena!');
      else openErrorSnackbar('Nepodařilo se načíst hodnocení');
      return;
    }
    setLessonRating(response.data.rating);
  };

  return {
    // Variables
    userData: subjectsData,
    subjectNames,
    selectionSubjectNames,
    activeSubject,
    teachingUnitsNames,
    selectionTeachingUnitsNames,
    activeTeachingUnit,
    lessonNames,
    selectionLessons,
    activeLesson,
    lessonData, 
    savedMaterials,
    lessons,
    studentsEmail,
    studentsClass,
    lessonProgress,
    lessonRating,
    // Functions
    setUserData: setSubjectsData,
    setSubjectNames,
    setActiveSubject,
    setTeachingUnitNames,
    setActiveTeachingUnit,
    setLessonNames,
    setActiveLesson,
    setLessonData,
    setSavedMaterials,
    setLessons,
    setSelectionSubjectNames,
    setSelectionTeachingUnitsNames,
    setSelectionLessons,
    setSubjectsInUserData: setSubjectsInSubjectsData,
    setTeachingUnitsInUserData: setTeachingUnitsInSubjectsData,
    setLessonsInUserData: setLessonsInSubjectsData,
    setNamesForDisplay,
    setLessonRating,
    obtainTeachingUnits,
    obtainLessons,
    handleOpenButton,
    rateLessonMaterials
  };
};
