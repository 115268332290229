const AboutWindow = () => {

  return (
    <section className='about-window'>
      <h1>O nás</h1>
      <p>
        V EDUBO věříme, že klíčem k modernímu a efektivnímu vzdělávání je dobře promyšlené plánování.
        Založeni v roce 2023, jsme se zavázali k poskytování pokročilých nástrojů a řešení pro učitele, 
        aby mohli efektivně plánovat a organizovat výukové hodiny. Naše platforma Eduklub je inovativní
        agregátor výukových celků, který umožňuje učitelům sdílet a stahovat výukové plány a materiály, 
        přizpůsobovat je a používat ve svých třídách.
      </p>
      <p>
        Díky našemu intuitivnímu editoru hodin mohou učitelé snadno plánovat aktivity, připojovat popisy, 
        odkazy, soubory a formy činností. Vytvořené plány se automaticky zobrazují studentům a rodičům na 
        jejich účtech, poskytují přehled nad nadcházejícími hodinami a umožňují přístup k veškerým potřebným 
        materiálům.
      </p>
      <p>
        Jsme hrdí na naši spolupráci se společností Mironet.cz a.s., která nám pomáhá přenášet naše vize do 
        praxe a přinášet nejnovější technologie do rukou učitelů a studentů.
      </p>
    </section>
  );
};

export default AboutWindow;
