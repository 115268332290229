import { KeyboardShortcuts } from 'utils/KeyboardShortcuts';
import { useModal } from "contexts/ModalProvider";

type MaterialDescriptionModalProps = {
  description: string;
  url_to_file?: string | undefined;
};

const MaterialDescriptionModal = ({description, url_to_file}: MaterialDescriptionModalProps) => {
  const { closeModal } = useModal();

  return (
    <div className='material-description-modal'>
      <KeyboardShortcuts context="materialDescriptionModal" />
      <h1>Popis uloženého materiálu:</h1>
      {description ?
        <p>{description}</p>
      :
        <p>Tento materiál nemá žádný popis.</p>
      }
      <div className="button-group">
        {url_to_file && (
          <a href={`/api/get-file/${url_to_file}`} target="_blank" rel="noopener noreferrer">
            <button type='button' className='download-button-modal px-2 py-1 text-sm text-white bg-[#8007AA] rounded-md'>
              Stáhnout
            </button>
          </a>
        )}
        <button type='button' id="closeButtonMaterialDescriptionModal" className="close-button" onClick={closeModal}>
          Zavřít
        </button>
      </div>
    </div>
  );
};

export default MaterialDescriptionModal;
