import { useModal } from 'contexts/ModalProvider';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { useEffect, useState } from 'react';
import type { parentKey } from 'types/StudentData';
import axiosRequest from 'utils/axios';

type ParentKeysResponse = {
  keys: parentKey[];
};

const ParentalAccess = () => {
  const { openErrorSnackbar } = useSnackbar();
  const { closeModal } = useModal();
  const [parentKeys, setParentKeys] = useState<parentKey[]>([]);

  useEffect(() => {
    getParentKeys();
  }, []);

  const getParentKeys = async () => {
    const response = await axiosRequest<ParentKeysResponse>("GET", 'api/current-student/parent-keys');
    if (!response.success) {
      console.error(response.errorMessage);
      openErrorSnackbar("Nepodařilo se získat rodičovské klíče!");
      return;
    }
    if (!response.data.keys || response.data.keys.length < 1) {
      openErrorSnackbar("Nemáte žádné rodičovské klíče");
      return;
    }
    setParentKeys(response.data.keys);
  };

  return (
    <div className="parental-access">
      <div className="text-content"> 
        <h2 className="title">Rodičovské klíče</h2>
        {parentKeys.map((key, index) => (
          <div className='key' key={index}>
            <p>{key.key}</p>
            {key.used && <span className="used">(použitý)</span>}
          </div>
        ))}
      </div>
      <button type='button' className="close-modal" onClick={closeModal}>Zavřít</button>
    </div>
  );
};

export default ParentalAccess;
