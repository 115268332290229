import type { CSSProperties } from "react";
import { Tooltip } from 'flowbite-react';
import Pending from "images/student-lesson-menu-icons/pending.svg?react";
import Ongoing from "images/student-lesson-menu-icons/ongoing.svg?react";
import Done from "images/student-lesson-menu-icons/done.svg?react";
import { MenuTypes } from "./StudentLessonMenu";

type StudentSelectionMenuProps = {
  list: string[];
  setList: (newTeachingUnitNames: string[]) => void;
  type: MenuTypes;
  selectionList: string[];
  setSelectionList: (list: string[]) => void;
  setActiveList: (list: string) => void;
  activeSubject: string;
  subjectSelect: boolean;
  obtainLessons: (subject: string, teachingUnit: string) => void;
  lessonProgress: string[] | undefined;
  mobile: boolean;
  handleOpenButton: (lesson: string) => void;
};

const StudentSelectionMenu = ({
    list,
    setList,
    type,
    selectionList,
    setSelectionList,
    setActiveList,
    activeSubject,
    subjectSelect,
    obtainLessons,
    lessonProgress,
    mobile,
    handleOpenButton
  }: StudentSelectionMenuProps) => {
  
  // Handles the click event for a given value
  const handleClick = (value: string) => {
    if (subjectSelect) {
      if (value === '') return setList([]);
      obtainLessons(activeSubject, value);
    }
    setActiveList(value);
    select(list.indexOf(value));
    if (type === MenuTypes.LESSON) {
      if (mobile) handleOpenButton(value);
    }
  };

  // Returns the CSS properties for styling an item based on its index
  const getItemStyle = (index: number): CSSProperties => {
    const selectedItem = selectionList[index] === "selected" ? "rgba(210, 174, 223, 1)" : "rgba(128, 7, 170, 0.1)";
    const borderColor =
      selectionList[index] === "selected"
        ? "rgb(185, 124, 208)"
        : "rgba(210, 174, 223, 1)";

    return {
      userSelect: "none",
      width: "100%",
      padding: 4,
      margin: "0 0 4px 0",
      border: `solid 1px ${borderColor}`,
      borderRadius: "4px",
      background: selectedItem,
      position: "relative",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer"
    };
  };

  // Updates the selection state based on the index
  const select = (index: number) => {
    const newSelection = new Array(list.length);
    newSelection.fill("unselected");
    newSelection[index] = "selected";
    setSelectionList(newSelection);
  };

  return (
    <div className="student-selection-menu">
      {list.map((value: string, index: number) => (
        <div className="student-lesson" key={index}>
          <div
            onClick={() => handleClick(value)}
            style={getItemStyle(index)}
            className={`student-lesson-name ${mobile ? "mobile" : ""}`}
          >
            {value}
          </div>
          {type === MenuTypes.LESSON && lessonProgress &&
            <Tooltip
              className="status-tooltip"
              content={lessonProgress[index] === "pending" ? "Neproběhlá hodina"
                : lessonProgress[index] === "ongoing" ? "Aktivní hodina"
                : lessonProgress[index] === "done" ? "Dokončená hodina"
                : null
              }
              placement='right'
              style='dark'
              trigger='hover'
            >
              {lessonProgress[index] === "pending" ? <Pending className="student-status-icon pending" />
                : lessonProgress[index] === "ongoing" ? <Ongoing className="student-status-icon ongoing" />
                : lessonProgress[index] === "done" ? <Done className="student-status-icon done" />
                : null
              }
            </Tooltip>
          }
        </div>
      ))}
    </div>
  );
};

export default StudentSelectionMenu;
