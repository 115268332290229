import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination } from "swiper";
import { CarouselPaths } from './CarouselPaths';

const Carousel = () => {
  return (
    <article className="carousel">
      <div className='carousel-window'>
        <Swiper 
          slidesPerView={1}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true
          }}
          pagination={{
            el: '.swiper-custom-pagination',
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
        >
          {CarouselPaths.map((slide, index) => (
            <SwiperSlide key={index} style={{backgroundImage: slide.path}} onClick={() => window.open(slide.url, '_blank', 'noopener,noreferrer')}/>
          ))}
        </Swiper>
          <div className="swiper-custom-pagination"/>
        </div>
    </article>
  );
}

export default Carousel;