import type { ReactNode } from 'react';
import { useMobileQuery } from './useMediaQuery';

type ResponsiveComponentProps = {
  desktop: ReactNode;
  mobile: ReactNode;
}

const ResponsiveComponent = ({ desktop, mobile }: ResponsiveComponentProps) => {
  const mobileViewport = useMobileQuery();

  return <>{mobileViewport ? mobile : desktop}</>;
};

export default ResponsiveComponent;
