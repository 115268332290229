import { Link, useNavigate } from "react-router-dom";
import useResetPassword from './useResetPassword';
import EduboIcon from "images/edubo.svg?react";
import ArrowIcon from "images/arrow.svg?react";
import InfoFooter from 'components/info-pages/InfoFooter';
import Eye from "images/eye.svg?react";
import EyeSlashed from "images/eye-slashed.svg?react";
import { useState } from 'react';

const PasswordResetNewPasswordWindow = () => {
  const navigate = useNavigate();
  const {registerPassword, resetPassword, handleSubmitPassword, errorsPassword, isSuccessfullySubmitted} = useResetPassword();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  return (
    <section className="password_reset_window">
      <div className='password_reset_container'>
        <EduboIcon className='logo'/>
        <form className="data" onSubmit={handleSubmitPassword(resetPassword)}>
          <div className="w-full">
            <div className='desc'>Zadejte nové heslo:</div>
            <input {...registerPassword("newPassword")} type={passwordShown ? "text" : "password"} className="input-password" maxLength={100} placeholder="Zadejte nové heslo" />
            {errorsPassword.newPassword && <p className="absolute mt-1 text-sm text-red-600">{errorsPassword.newPassword.message}</p>}
            <div className="flex flex-col relative">
              <div className='desc'>Potvrďte nové heslo:</div>
              <input {...registerPassword("confirmPassword")} type={passwordShown ? "text" : "password"} className="input-password" maxLength={100} placeholder="Znovu zadejte nové heslo" />
              {passwordShown ?
                <Eye className="eye shown" onClick={() => setPasswordShown(!passwordShown)} />
              : 
                <EyeSlashed className="eye" onClick={() => setPasswordShown(!passwordShown)} />
              }
            </div>
            {errorsPassword.confirmPassword && <p className="absolute mt-1 text-sm text-red-600">{errorsPassword.confirmPassword.message}</p>}
            {errorsPassword.apiError && <p className={`absolute mt-1 text-sm text-red-600  ${errorsPassword.confirmPassword ? "mt-5" : "mt-1"}`}>{errorsPassword.apiError?.message}</p>}
            {isSuccessfullySubmitted && (<p className="mt-1 text-sm absolute text-green-600">Heslo bylo změněno!</p>)}
          </div>
          <button type="submit" className="select-none">Změnit heslo</button>
          <div className='back-to-login' onClick={() => navigate("/")}>
            <ArrowIcon className='back-to-login-arrow' />
            <Link to={"/"} className="back-to-login-text">Zpět na přihlášení</Link>
          </div>
        </form>
      </div>
      <InfoFooter page={"login"}/>
    </section>
  );
};

export default PasswordResetNewPasswordWindow;
