const ContactsWindow = () => {

  return (
    <section className='contacts-window'>
      <h1>Kontakty</h1>
      <p>
        Pokud máte dotazy, připomínky nebo byste se chtěli dozvědět více o tom, jak může EDUBO a Eduklub 
        pomoci ve vaší výuce, neváhejte nás kontaktovat.
      </p>
      <h2>Kontaktní Email: podpora@eduklub.cz</h2>
      <p>
        Pro více informací nás můžete také navštívit naše webové stránky.
      </p>
    </section>
  );
};

export default ContactsWindow;
