import Carousel from 'components/login/Carousel';
import ResponsiveComponent from 'utils/ResponsiveComponent';
import { useWindowSize } from 'utils/useWindowSize';
import { Link } from 'react-router-dom';
import InfoFooter from 'components/info-pages/InfoFooter';
import AppBar from 'components/mobile-utils/AppBar';
import { useSelector } from 'react-redux';
import AboutWindow from 'components/info-pages/AboutWindow';
import ContactsWindow from 'components/info-pages/ContactsWindow';
import GdprWindow from 'components/info-pages/GdprWindow';
import UpdateNoteWindow from 'components/info-pages/UpdateNoteWindow';
import type { RootState } from 'redux/store';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/browserUtils';

type InfoPageProps = {
  page: "about" | "notes" | "contacts" | "gdpr" | "login";
};

type TPages = {
  [key: string]: JSX.Element;
};

const pages: TPages = {
  about: <AboutWindow/>,
  contacts: <ContactsWindow/>,
  gdpr: <GdprWindow/>,
  notes: <UpdateNoteWindow/>
};

const InfoPage = ({page}: InfoPageProps) => {
  const windowSize = useWindowSize();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const pageName = 
    page === "about" ? "O aplikaci" :
    page === "contacts" ? "Kontakty" :
    page === "gdpr" ? "Zpracování dat" :
    page === "notes" ? "Archiv aktualit" : "";

  return (
    <section className="info-page">
      <Helmet>
        <title>EDUBO – {pageName}</title>
        <link rel='canonical' href={`${websiteUrl}/${page}`}/>
      </Helmet>
      <ResponsiveComponent
        desktop={
          <>
            {(windowSize[0] - 32 > windowSize[1]) && <Carousel />}
            <div className='main-content'>
              {pages[page]}
              <InfoFooter page={page}/>
            </div>
          </>
        }
        mobile={
          <div className='main-content mobile'>
            <AppBar/>
            {pages[page]}
            {userInfo._id === undefined && <Link to={"/"}><button type="button" className='back-to-login'>Zpět na přihlášení</button></Link>}
          </div>
        }
      />
    </section>
  );
};

export default InfoPage;
