import './App.scss';
import App from './App';
import { Provider } from 'react-redux';
import { persistedStore, store } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import * as ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

/* import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: "https://372a566f06f7d1d65926649f717b3829@o4506184973352960.ingest.sentry.io/4506185189687296",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [
      new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} */

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </PersistGate>
  </Provider>
)
