const UpdateNoteWindow = () => {

  return (
    <section className='update-notes-window'>
      <h1>Archiv aktualit</h1>
      <div className="version">
        <h2>Verze 1.1.0</h2>
        <p>
          Vylepšené UI/UX
        </p>
        <p>
          Opraveno mnoho chyb, které způsobovaly pád aplikace
        </p>
        <p>
          Optimalizace celé aplikace
        </p>
        <p>
          Refaktorizace kódu
        </p>
      </div>
      <div className="version">
        <h2>Verze 1.0.0</h2>
        <p>
          Prvotní verze aplikace EDUBO
        </p>
      </div>
    </section>
  );
};

export default UpdateNoteWindow;
