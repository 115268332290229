import { useModal } from 'contexts/ModalProvider';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'redux/authSlice';
import { setClass } from 'redux/editorSlice';
import type { RootState } from 'redux/store';
import axiosRequest from 'utils/axios';

type ChangeClassResponse = {
  class_id: string;
  class_name: string;
};

const ChangeClass = () => {
  const { openErrorSnackbar, openSuccessSnackbar } = useSnackbar();
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [inputValue, setInputValue] = useState<string>('');

  const handleChangeClass = async () => {
    const response = await axiosRequest<ChangeClassResponse>("POST", 'api/student/change-class',
      { key: inputValue }
    );
    if (!response.success) {
      if (response.errorMessage.includes("Key not found")) openErrorSnackbar("Klíč nebyl nalezen!")
      else if (response.errorMessage.includes("Key already used")) openErrorSnackbar("Klíč byl již použit!")
      else if (response.errorMessage.includes("Key expired")) openErrorSnackbar("Klíč vypršel!")
      else {
        console.error(response.errorMessage);
        openErrorSnackbar("Nepodařilo se získat rodičovské klíče!");
      }
      return;
    }
    const newUserInfo = JSON.parse(JSON.stringify(userInfo));
    newUserInfo.class_id = response.data.class_id;
    const rememberMe = localStorage.getItem('userInfo') === 'true';
    dispatch(setUser({userInfo: newUserInfo, rememberMe: rememberMe}));
    dispatch(setClass(response.data.class_name));
    openSuccessSnackbar("Třída byla úspěšně změněna!");
    closeModal();
  };

  return (
    <div className="change-class">
      <h2 className="title">Změna třídy</h2>
      <div className="text-content"> 
        <p>Napište nový kód:</p>
        <input type='text' onChange={(e) => setInputValue(e.target.value)}/>
      </div>
      <div className='buttons'>
        <button type='button' className="close-modal" onClick={closeModal}>Zavřít</button>
        <button type='button' className="change-class-button" onClick={handleChangeClass}>Změnit třídu</button>
      </div>
    </div>
  );
};

export default ChangeClass;
