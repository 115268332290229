import { createContext, useContext, useState, type ReactNode } from 'react';
import SuccessSnackbar from './SuccessSnackbar';
import ErrorSnackbar from './ErrorSnackbar';

type SnackbarContextType = {
  openSuccessSnackbar: (msg: string) => void;
  openErrorSnackbar: (msg: string) => void;
  closeSnackbar: () => void;
  forceCloseSnackbar: () => void;
};

export const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [fade, setFade] = useState("fade-in");
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<"success" | "error">("success");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const openSnackbar = (msg: string, type: "success" | "error") => {
    setType(type);
    setMessage(msg);
    setOpen(true);
  
    // Automatically close the snackbar after 4 seconds
    if (timer) clearTimeout(timer);
    
    setTimer(
      setTimeout(() => {
        closeSnackbar();
    }, 4000));
  };

  const openErrorSnackbar = (msg: string) => {
    openSnackbar(msg, "error");
  };

  const openSuccessSnackbar = (msg: string) => {
    openSnackbar(msg, "success");
  };

	const closeSnackbar = () => {
		setFade("fade-out");
    setTimeout(() => {
      setOpen(false);
      setMessage("");
      setType("success");
    }, 300);
		setTimeout(() => {
			setFade("fade-in");
		}, 300);
	};

	const forceCloseSnackbar = () => {
		setOpen(false);
		setMessage("");
		setType("success");
	};

  return (
    <SnackbarContext.Provider value={{ openSuccessSnackbar, openErrorSnackbar, closeSnackbar, forceCloseSnackbar }}>
      {children}
      {open && (
        type === "success" ? <SuccessSnackbar message={message} closeSnackbar={forceCloseSnackbar} fade={fade} />
        : <ErrorSnackbar message={message} closeSnackbar={forceCloseSnackbar} fade={fade} />
      )} 
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const currentContext = useContext(SnackbarContext);

  if (!currentContext) {
    throw new Error('useSnackbar must be used within SnackbarProvider');
  }

  return currentContext;
};
